import { forwardRef } from "react";
import { Input } from "@material-tailwind/react";
import PropTypes from "prop-types";

export const TextField = forwardRef(
  (
    {
      titulo,
      onChange,
      value,
      cep = false,
      telefone = false,
      horario = false,
      disabled = false,
      autoFocus = false,
      dinheiro = false,
      maxLength,
      width,
      nextRef,
    },
    ref
  ) => {
    var onKeyUp = null;
    var type = null;
    if (cep) {
      maxLength = "9";
      type = "text";
      onKeyUp = (v) => {
        v = v.replace(/\D/g, "");
        v = v.replace(/^(\d{5})(\d)/, "$1-$2");
        return v;
      };
    }
    if (telefone) {
      onKeyUp = (v) => {
        v = v.replace(/\D/g, "");
        v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
        v = v.replace(/(\d)(\d{4})$/, "$1-$2");
        return v;
      };
      maxLength = "15";
      type = "text";
    }
    if (horario) {
      onKeyUp = (v) => {
        v = v.replace(/\D/g, "");
        v = v.replace(/^(\d{2})(\d)/g, "$1:$2");
        return v;
      };
      maxLength = "5";
      type = "text";
    }
    if (dinheiro) {
      onKeyUp = (v) => {
        v = v.replace(/\D/g, "");
        v = v.replace(/(\d)(\d{2})$/, "$1.$2");
        return v;
      };
      maxLength = "10";
      type = "text";
    }

    return (
      <>
        <div style={{ flexDirection: "column" }} className="relative w-full">
          <div className="relative w-full">
            <Input
              onKeyPress={(e) => {
                if (e.key === "Enter" && nextRef) {
                  e.preventDefault();
                  nextRef();
                }
              }}
              inputRef={ref}
              autoFocus={autoFocus}
              value={value}
              onChange={
                onKeyUp == null
                  ? (e) => onChange(e)
                  : (e) => {
                      e.target.value = onKeyUp(e.target.value);
                      onChange(e);
                    }
              }
              label={titulo}
              disabled={disabled}
              type={type}
              maxLength={maxLength}
              onKeyUp={
                onKeyUp == null
                  ? null
                  : (e) => {
                      e.target.value = onKeyUp(e.target.value);
                    }
              }
              style={{ width: width }}
            />
          </div>
          <div className="h-2"></div>
        </div>
      </>
    );
  }
);

TextField.propTypes = {
  titulo: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextField;
