import {
  BanknotesIcon,
  UserPlusIcon
} from "@heroicons/react/24/outline";
import { Button, Card } from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useCodigoLocal from "../../hooks/useCodigoLocal";
import useEditPedido from "../../hooks/useEditPedido";
import useIdLocal from "../../hooks/useIdLocal";
import useToken from "../../hooks/useToken";
import { ApiPedidos } from "../../services/pedidos";
import urlLsFast from "../../utils/globalVars";
import verificarHoraFuncionamento from "../../utils/verificar_hora_funcionamento";
import { NovoPedidoCards } from "../../widgets/cards";
import { DropdownComponent, TextField } from "../../widgets/inputs";
import Row from "../../widgets/layout/row";

export function CadastroPedido() {
  const navigate = useNavigate();
  const { token } = useToken();
  const { idLocal } = useIdLocal();
  const { codigoLocal } = useCodigoLocal();
  const { pedidoCompleto, setPedidoCompleto } = useEditPedido();

  const [tab, setTab] = useState("endereco");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [pontoReferencia, setPontoReferencia] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("SP");
  const [nomeCliente, setNomeCliente] = useState("");
  const [valorReceber, setValorReceber] = useState(0);
  const [telefone, setTelefone] = useState("");
  const [horario, setHorario] = useState("");
  const [pedido, setPedido] = useState("");
  const [isValorDaCorrida, setIsValorDaCorrida] = useState(false);
  const [isHorario, setIsHorario] = useState(false);
  const [erroBuscarCep, setErroBuscarCep] = useState(true);

  const numeroRef = useRef(null);
  const complementoRef = useRef(null);
  const pontoReferenciaRef = useRef(null);
  const telefoneRef = useRef(null);
  const valorRef = useRef(null);
  const horarioRef = useRef(null);

  useEffect(() => {
    if (pedidoCompleto) {
      let os = pedidoCompleto.os;
      if (os.includes("-")) {
        const dados = os.split("-");
        dados.shift();
        os = dados.join("-");
      }
      setCep(pedidoCompleto.cep);
      setPedido(os);
      setIsValorDaCorrida(pedidoCompleto.receberValor);
      setIsHorario(pedidoCompleto.alterarHorario);
      setNumero(pedidoCompleto.numero);
      setEndereco(pedidoCompleto.endereco);
      setBairro(pedidoCompleto.bairro);
      setCidade(pedidoCompleto.cidade);
      setUf(pedidoCompleto.uf);
      setNomeCliente(pedidoCompleto.clienteNome);
      setTelefone(pedidoCompleto.telefone);
      setValorReceber(pedidoCompleto.valorReceber);
      setHorario(pedidoCompleto.horario);
      setPontoReferencia(pedidoCompleto.pontoReferencia);
      setComplemento(pedidoCompleto.complemento);

      setPedidoCompleto(null);
      setTab("entrega");
    }
  }, [pedidoCompleto, setPedidoCompleto]);

  const buscarCep = async (cep) => {
    if (cep.length < 9) {
      return;
    }
    try {
      const url = `${urlLsFast}/servicos/buscacep?cep=${cep}`;
      const response = await fetch(url, { method: "GET" });
      const data = await response.json();

      if (!data) {
        Swal.fire("Erro", "Ocorreu um erro!", "error");
      } else {
        setEndereco(data["Logradouro"]);
        setBairro(data["Bairro"]);
        setCidade(data["Cidade"]);
        setUf(data["Estado"]);
        numeroRef.current.focus();
      }
      setErroBuscarCep(true);
    } catch (error) {
      Swal.fire("CEP", "CEP não encontrado. Digite o endereço da entrega", "info");
      setErroBuscarCep(false);
    }
  };

  const addPedido = async () => {
    let novoHorario = null;
    if (isHorario) {
      const data = new Date();
      const ano = data.getFullYear();
      const mes = data.getMonth() + 1;
      const dia = data.getDate();
      const horarioNumeros = horario.replace(/[^0-9]/g, "").padEnd(4, "0");
      const newHorarioNumeros = `${horarioNumeros.substring(0, 2)}:${horarioNumeros.substring(2)}`;
      novoHorario = `${ano}-${mes.toString().padStart(2, "0")}-${dia.toString().padStart(2, "0")}T${newHorarioNumeros}:00.000`;
    }
    const novoValorCorrida = isValorDaCorrida ? valorReceber : 0;
    const body = {
      IDLocal: idLocal,
      IDTipo: 23,
      Cep: cep,
      Endereco: endereco,
      Numero: numero,
      Complemento: complemento,
      Referencia: pontoReferencia,
      Bairro: bairro,
      Cidade: cidade,
      UF: uf,
      Telefone: telefone,
      Pedido: `${codigoLocal}-${pedido}`,
      Valor: novoValorCorrida,
      NomeCliente: nomeCliente,
      NotaFiscal: `${codigoLocal}-${pedido}`,
      ValorCorrida: novoValorCorrida,
      Horario: novoHorario,
    };
    const response = await ApiPedidos.criarNovo(token, body);
    if (response === "") {
      setPedido(null);
      navigate("/dashboard/pedidos");
    } else {
      Swal.fire("Erro", response, "error");
    }
  };

  const validarCampos = async () => {
    if (!cep) {
      Swal.fire("Erro", "CEP inválido", "error");
      return false;
    }
    if (!numero) {
      Swal.fire("Erro", "Número inválido", "error");
      return false;
    }
    if (!cidade) {
      Swal.fire("Erro", "Cidade inválida", "error");
      return false;
    }
    if (!uf) {
      Swal.fire("Erro", "UF inválida", "error");
      return false;
    }
    if (!nomeCliente) {
      Swal.fire("Erro", "Nome do cliente inválido", "error");
      return false;
    }
    if (!telefone) {
      Swal.fire("Erro", "Telefone inválido", "error");
      return false;
    }
    if (!pedido) {
      Swal.fire("Erro", "Pedido inválido", "error");
      return false;
    }
    if (isValorDaCorrida && !valorReceber) {
      Swal.fire("Erro", "Valor a receber inválido", "error");
      return false;
    }
    if (isHorario && !(await verificarHoraFuncionamento(token, horario))) {
      return false;
    }
    return true;
  };

  const tabs = [
    { label: "Endereço", value: "endereco" },
    { label: "Cliente", value: "cliente" },
    { label: "Pedido", value: "pedido" },
    { label: "Entrega", value: "entrega" },
  ];

 const dataCards = [
    {
      color: "blue",
      icon: BanknotesIcon,
      title: "Endereço de Coleta",
      values: [
        { title: "Rua", text: `${endereco}, ${numero}` },
        { title: "Complemento", text: complemento },
        { title: "Referência", text: pontoReferencia },
        { title: "Bairro", text: bairro },
        { title: "Cidade", text: `${cidade}/${uf}` },
      ],
    },
    {
      color: "green",
      icon: UserPlusIcon,
      title: "Dados do Cliente",
      values: [
        { title: "Nome", text: nomeCliente },
        { title: "Telefone", text: telefone },
      ],
    },
  ];

  if (isValorDaCorrida) {
    dataCards[2].values.push({
      title: "Valor a receber",
      text: valorReceber,
      color: "orange",
    });
  }

  if (isHorario) {
    dataCards[2].values.push({
      title: "Horário de coleta na loja",
      text: horario,
      color: "orange",
    });
  }

  return (
    <Card>
      <div className="m-4">
        <Row>
          {tabs.map(({ label, value }) => (
            <div className="w-full" key={value}>
              <Button
                onClick={() => setTab(value)}
                style={{
                  backgroundColor: tab === value ? "#FFFFFF" : "#ECEFF1",
                  boxShadow: tab === value ? "2px 2px 4px #ECEFF1" : "0 0 0 0",
                }}
                className="w-full"
              >
                <div style={{ color: "black" }}>{label}</div>
              </Button>
            </div>
          ))}
        </Row>
        {tab === "endereco" && (
          <>
            <div className="h-5" />
            <Row>
              <TextField
                autoFocus
                cep
                value={cep}
                titulo="CEP do cliente"
                onChange={async (e) => {
                  setCep(e.target.value);
                  await buscarCep(e.target.value);
                }}
              />
              <TextField
                titulo="Endereço"
                value={endereco}
                onChange={(e) => setEndereco(e.target.value)}
                disabled={erroBuscarCep}
              />
            </Row>
            <Row>
              <TextField
                ref={numeroRef}
                nextRef={() => complementoRef.current.focus()}
                titulo="Número"
                value={numero}
                maxLength="50"
                onChange={(e) => setNumero(e.target.value)}
              />
              <TextField
                ref={complementoRef}
                nextRef={() => pontoReferenciaRef.current.focus()}
                titulo="Complemento"
                value={complemento}
                maxLength="250"
                onChange={(e) => setComplemento(e.target.value)}
              />
            </Row>
            <Row>
              <TextField
                ref={pontoReferenciaRef}
                titulo="Ponto de referência"
                value={pontoReferencia}
                maxLength="150"
                onChange={(e) => setPontoReferencia(e.target.value)}
              />
              <TextField
                titulo="Bairro"
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
                disabled={erroBuscarCep}
              />
            </Row>
            <Row>
              <TextField
                titulo="Cidade"
                value={cidade}
                onChange={(e) => setCidade(e.target.value)}
                disabled={erroBuscarCep}
              />
              <div style={{ width: "100%" }}>
                <DropdownComponent
                  disabled={erroBuscarCep}
                  titulo="UF"
                  value={uf}
                  dados={[
                    "AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA",
                    "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN",
                    "RS", "RO", "RR", "SC", "SP", "SE", "TO"
                  ]}
                  onChange={setUf}
                />
              </div>
            </Row>
            <div className="h-5" />
            <Row>
              <div className="w-full"></div>
              <div className="w-full"></div>
              <Button className="w-full mx-2" onClick={() => setTab("cliente")}>
                Próximo
              </Button>
            </Row>
          </>
        )}
        {tab === "cliente" && (
          <>
            <div className="h-5" />
            <Row>
              <TextField
                autoFocus
                nextRef={() => telefoneRef.current.focus()}
                titulo="Nome e Sobrenome do Cliente"
                maxLength="255"
                value={nomeCliente}
                onChange={(e) => setNomeCliente(e.target.value)}
              />
              <TextField
                ref={telefoneRef}
                titulo="Telefone"
                telefone
                value={telefone}
                maxLength="50"
                onChange={(e) => setTelefone(e.target.value)}
              />
            </Row>
            <div className="h-5"></div>
            <Row>
              <Button className="w-full mx-2" onClick={() => setTab("endereco")}>
                Voltar
              </Button>
              <div className="w-full"></div>
              <Button className="w-full mx-2" onClick={() => setTab("pedido")}>
                Próximo
              </Button>
            </Row>
          </>
        )}
        {tab === "pedido" && (
          <>
            <div className="h-5" />
            <Row>
              <div style={{ width: 100 }}>
                <TextField value={codigoLocal} disabled width={100} />
              </div>
              <TextField
                autoFocus
                titulo="Cupom ou número do pedido"
                value={pedido}
                maxLength={(50 - (codigoLocal.toString().length + 1)).toString()}
                onChange={(e) => setPedido(e.target.value)}
              />
            </Row>
            <div className="h-2"></div>
            <Row>
              {/* Add your additional form fields and switch components here */}
            </Row>
            <div className="h-5"></div>
            <Row>
              <Button className="w-full mx-2" onClick={() => setTab("cliente")}>
                Voltar
              </Button>
              <div className="w-full"></div>
              <Button className="w-full mx-2" onClick={() => setTab("entrega")}>
                Próximo
              </Button>
            </Row>
          </>
        )}
        {tab === "entrega" && (
          <>
            <div className="h-5" />
            <Row>
              {dataCards.map(({
                color,
                icon, title, values }, index) => (
                <div className="w-full" key={index}>
                  <NovoPedidoCards
                    color={color}
                    icon={React.createElement(icon, { className: "w-6 h-6 text-white" })}
                    title={title}
                    values={values}
                  />
                </div>
              ))}
            </Row>
            <div className="h-10" />
            <Row>
              <Button className="w-full" onClick={() => setTab("pedido")}>
                Voltar
              </Button>
              <div className="w-full"></div>
              <Button
                className="w-full mx-2 bg-green-500 hover:bg-green-600"
                onClick={async () => {
                  const isValid = await validarCampos();
                  if (isValid) {
                    addPedido();
                  }
                }}
              >
                Confirmar Pedido
              </Button>
            </Row>
          </>
        )}
      </div>
    </Card>
  );
}

export default CadastroPedido;
