import { useState } from "react";

export default function useInfoLoja() {
  function validateUrl(url) {
    if (url == null || url == undefined) return false;
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  function getInfoLoja() {
    const Nome = sessionStorage.getItem("Nome");
    const Endereco = sessionStorage.getItem("Endereco");
    let Imagem = sessionStorage.getItem("Imagem");
    const Latitude = sessionStorage.getItem("Latitude");
    const Longitude = sessionStorage.getItem("Longitude");

    if (!validateUrl(Imagem)) {
      Imagem = "https://cdn-icons-png.flaticon.com/512/3135/3135768.png";
    }

    return { Nome, Endereco, Imagem, Latitude, Longitude };
  }

  const [infoLoja, setInfoLoja] = useState(getInfoLoja());

  const saveInfoLoja = (infoLoja) => {
    sessionStorage.setItem("Nome", infoLoja.Nome);
    sessionStorage.setItem("Endereco", infoLoja.Endereco);
    sessionStorage.setItem("Imagem", infoLoja.Imagem);
    sessionStorage.setItem("Latitude", infoLoja.Latitude);
    sessionStorage.setItem("Longitude", infoLoja.Longitude);
    setInfoLoja(infoLoja);
  };

  return {
    setInfoLoja: saveInfoLoja,
    infoLoja,
  };
}
