import {
  BanknotesIcon,
  UserIcon,
  UserPlusIcon,
} from "@heroicons/react/24/solid";

export const statisticsCardsData = [
  {
    index: 0,
    color: "blue",
    icon: BanknotesIcon,
    title: "Pedidos do mês",
    value: "0",
    footer: {
      color: "text-green-500",
      value: "+56%",
      label: "Pedidos no mês anterior",
    },
  },
  {
    index: 1,
    color: "pink",
    icon: UserIcon,
    title: "Entregas não concluídas no mês",
    value: "0",
    footer: {
      color: "text-green-500",
      value: "+3%",
      label: "entregas não concluídas no mês anterior",
    },
  },
  {
    index: 2,
    color: "green",
    icon: UserPlusIcon,
    title: "Clientes do mês",
    value: "0",
    footer: {
      color: "text-red-500",
      value: "",
      label: "",
    },
  },
];

export default statisticsCardsData;
