import { useState } from "react";

export default function useNome() {
  function getNome() {
    const tokenString = sessionStorage.getItem("name");
    return tokenString;
  }

  const [nome, setNome] = useState(getNome());

  const saveNome = (userNome) => {
    sessionStorage.setItem("name", userNome);
    setNome(userNome);
  };

  return {
    setNome: saveNome,
    nome,
  };
}
