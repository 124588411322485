import React from 'react';

const ItemList = ({ items, handleCheckboxChange }) => {
  return (
    <div>
      {items.map((item, index) => (
        <div key={index} className="border border-gray-300 rounded p-4 mb-4">
          <div className="flex items-center mb-2">
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, item.id)}
              className="w-6 h-6 mr-2"
            />
            <h3 className="mr-2 font-bold text-lg">{item.os} - </h3>
            <h3 className="font-bold text-lg" style={{ textTransform: 'uppercase' }}>
              {item.clienteNome}
            </h3>
          </div>
          <p className="text-sm text-gray-500">{item.enderecoCliente}</p>
        </div>                       
      ))}
    </div>
  );
};

export default ItemList;
