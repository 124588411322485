import { useState } from "react";

export default function useCodigoLocal() {
  function getCodigoLocal() {
    const valueString = sessionStorage.getItem("codigoLocal");
    return valueString;
  }

  const [codigoLocal, setCodigoLocal] = useState(getCodigoLocal());

  const saveCodigoLocal = (userToken) => {
    sessionStorage.setItem("codigoLocal", userToken);
    setCodigoLocal(userToken);
  };

  return {
    setCodigoLocal: saveCodigoLocal,
    codigoLocal,
  };
}
