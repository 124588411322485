export function TableCustom({ categoria, ...dados }) {
  return (
    <>
      <div
        className={
          dados.dados.length == 0 ? "flex flex-col w-full" : "flex flex-col"
        }
      >
        <div
          className={
            dados.dados.length == 0
              ? "overflow-x-auto w-full"
              : "overflow-x-auto"
          }
        >
          <div className="p-1.5 w-full inline-block align-middle ">
            <div
              className={
                dados.dados.length == 0
                  ? "overflow-hidden border rounded-lg w-full"
                  : "overflow-hidden border rounded-lg"
              }
            >
              <table
                className={
                  dados.dados.length == 0
                    ? "max-x-full divide-y divide-gray-200 w-full"
                    : "max-x-full divide-y divide-gray-200"
                }
              >
                <thead className="bg-gray-50">
                  <tr>
                    {categoria.map((item) => {
                      return (
                        <th
                          scope="col"
                          className="px-3 py-3 text-xs font-bold text-left text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          {item}
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 ">
                  {dados.dados.map((item) => {
                    return (
                      <tr>
                        <td
                          className="px-3 text-sm text-gray-800 whitespace-nowrap 
                          cursor-pointer hover:text-blue-500
                        "
                        >
                          <a
                            href={`https://lstranslog.com.br/rastreio?cep=${item.CEP}&pedido=${item.OS}`}
                            target="_blank"
                            className="text-blue-500"
                          >
                            <b>{item.OS}</b>
                          </a>
                        </td>
                        <td className="px-3 text-sm text-gray-800">
                          {item.Status}
                        </td>
                        <td className="px-3 text-sm text-gray-800 ">
                          {item.Modalidade}
                        </td>
                        <td className="px-3 text-sm text-gray-800">
                          {item.Cliente}
                        </td>
                        <td className="px-3 text-sm text-gray-800">
                          {item.NomeRecebedor}
                        </td>
                        <td className="px-3 text-sm text-gray-800  whitespace-nowrap ">
                          {item.DataEntrada}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item.DataTermino}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-800 ">
                          {item.Endereco}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TableCustom;
