import { ArrowLeftOnRectangleIcon, Bars3Icon } from "@heroicons/react/24/solid";
import {
  Button,
  IconButton,
  Navbar,
  Typography,
} from "@material-tailwind/react";
import { useLocation, useNavigate } from "react-router-dom";
import { setOpenSidenav, useMaterialTailwindController } from "../../context";
import useToken from "../../hooks/useToken";

export function DashboardNavbar() {
  const { token, setToken } = useToken();
  const navigate = useNavigate();

  const [controller, dispatch] = useMaterialTailwindController();
  const { fixedNavbar, openSidenav } = controller;
  const { pathname } = useLocation();
  const [layout, page] =
    pathname == "/dashboard/cadastro/pedido"
      ? ["", "Cadastrar Pedido"]
      : pathname == "/dashboard/cadastro/pedido/receita"
      ? ["", "Cadastrar Pedido"]
      : pathname.split("/").filter((el) => el !== "");

  function logout() {
    setToken(null);
    navigate("/auth/signin?logout=true");
    window.location.href = "https://lstranslog.com.br/login";
  }

  return (
    <Navbar
      color={fixedNavbar ? "white" : "transparent"}
      className={`rounded-xl transition-all ${
        fixedNavbar
          ? "sticky top-4 z-40 py-3 shadow-md shadow-blue-gray-500/5"
          : "px-0 py-1"
      }`}
      fullWidth
      blurred={fixedNavbar}
    >
      <div className="flex flex-col-reverse justify-between gap-6 md:flex-row md:items-center">
        <div className="capitalize">
          <Typography variant="h6" color="blue-gray">
            {page}
          </Typography>
        </div>
        <div className="flex items-center">
          <IconButton
            variant="text"
            color="blue-gray"
            className="grid xl:hidden"
            onClick={() => setOpenSidenav(dispatch, !openSidenav)}
          >
            <Bars3Icon strokeWidth={3} className="h-6 w-6 text-blue-gray-500" />
          </IconButton>
          <Button
            variant="text"
            color="blue-gray"
            className="hidden items-center gap-1 px-4 xl:flex"
            onClick={logout}
          >
            <ArrowLeftOnRectangleIcon className="h-5 w-5 text-blue-gray-500" />
            Sair
          </Button>
          <IconButton
            variant="text"
            color="blue-gray"
            className="grid xl:hidden"
            onClick={logout}
          >
            <ArrowLeftOnRectangleIcon className="h-5 w-5 text-blue-gray-500" />
          </IconButton>
        </div>
      </div>
    </Navbar>
  );
}

DashboardNavbar.displayName = "/src/widgets/layout/dashboard-navbar.jsx";

export default DashboardNavbar;
