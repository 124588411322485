import { useState } from "react";

export default function useEditPedido() {
  function getPedido() {
    try {
      const tokenString = sessionStorage.getItem("pedido");
      if (!tokenString) return null;
      const token = JSON.parse(tokenString);
      return token;
    } catch (error) {
      return null;
    }
  }

  const [pedido, setPedido] = useState(getPedido());

  const savePedido = (userPedido) => {
    var stringJson = JSON.stringify(userPedido);
    sessionStorage.setItem("pedido", stringJson);
    setPedido(userPedido);
  };

  return {
    setPedidoCompleto: savePedido,
    pedidoCompleto: pedido,
  };
}
