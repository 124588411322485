import { CardHeader } from "@material-tailwind/react";
import { isHomologacao } from "../../utils/globalVars";

export function HomologacaoCard() {
  console.log(process.env);
  return (
    <>
      {isHomologacao ? (
        <CardHeader
          className="mt-3 mb-4 grid h-14 place-items-center "
          style={{
            background: "rgb(255, 90, 6)",
          }}
        >
          <h1 className="text-white font-bold">Homologação</h1>
        </CardHeader>
      ) : null}
    </>
  );
}

export default HomologacaoCard;
