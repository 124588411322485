// crair card com perfil da loja

import React from "react";
import useInfoLoja from "../../hooks/useInfoLoja";

import { Card, CardHeader } from "@material-tailwind/react";

export function CardPerfilLoja() {
  const { infoLoja, setInfoLoja } = useInfoLoja();
  return (
    <Card>
      <CardHeader
        variant="gradient"
        className="absolute -mt-4 grid  place-items-center rounded-full bg-white shadow-lg"
        style={{
          top: "10%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          //   ELEVATION DA IMAGEM
          boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
        }}
      >
        <img
          className="object-cover w-28 h-28 rounded-full "
          src={infoLoja.Imagem}
          alt="imagem da loja"
        />
      </CardHeader>{" "}
      <div className="text-center ">
        <br></br>
      </div>
      <div className="text-center mt-20 mb-10 px-12">
        <h2 className="text-sm text-gray-500">{infoLoja.Nome}</h2>
        <br></br>
        <p className="text-1xl font-semibold text-gray-700">
          {infoLoja.Endereco}
        </p>
      </div>
      {/* botao para entrar no mapa */}
      <a
        // linkEndereco={
        //   "https://www.google.com/maps/search/" +
        //   infoLoja.Latitude +
        //   "," +
        //   infoLoja.Longitude
        // }
        href={
          "https://www.google.com/maps/search/" +
          infoLoja.Latitude +
          "," +
          infoLoja.Longitude
        }
        target="_blank"
        rel="noreferrer"
        className="flex justify-center"
        style={{
          marginLeft: "20px",
        }}
      >
        <button
          className="hover:bg-blue-700 text-white font-bold py-3 px-5 rounded-full"
          style={{
            // 9c27b0
            backgroundColor: "#9c27b0",
          }}
        >
          MAPA
        </button>
      </a>
      <br></br>
    </Card>
  );
}

export default CardPerfilLoja;
