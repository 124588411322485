import { ClockIcon } from "@heroicons/react/24/outline";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { chartsConfig } from "../../configs";
import { statisticsCardsData, statisticsChartsData } from "../../data";
import useToken from "../../hooks/useToken";
import { DadosHome, jsonDefaultDadosHome } from "../../models/dadosHome";
import urlLsFast from "../../utils/globalVars";
import { StatisticsCard } from "../../widgets/cards";
import { StatisticsChart } from "../../widgets/charts";
import { CardPerfilLoja } from "./card_perfil_loja";
export function Home() {
  const { token, setToken } = useToken();
  const [loadInicial, setLoadInicial] = useState(false);

  const [dadosHome, setDadosHome] = useState(
    new DadosHome(jsonDefaultDadosHome)
  );

  async function cardConsultar() {
    const response = await fetch(urlLsFast + "/dashboard/dados", {
      headers: {
        accept: "*/*",
        token: token,
      },
    });

    const data = await response.json();
    if (data == null) {
    } else {
      return new DadosHome(data);
    }
  }

  useEffect(() => {
    if (loadInicial) return;
    setLoadInicial(true);
    async function fetchData() {
      var newDados = await cardConsultar();

      if (newDados != null) {
        await setDadosHome(newDados);
      }
    }
    fetchData();
  }, []);
  return (
    <div className="mt-12">
      <div className="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-3">
        {statisticsCardsData.map(({ index, icon, title, footer, ...rest }) => {
          var infoPrincipal = null;
          var infoSecundaria = null;
          if (index == 0) {
            infoPrincipal = dadosHome.Cards.PedidosMes.PedidosMes ?? 0;
            infoSecundaria =
              dadosHome.Cards.PedidosMes.ComparadoMesAnterior ?? 0;
          } else if (index == 1) {
            infoPrincipal =
              dadosHome.Cards.PedidosNaoEntregues.PedidosNaoEntregues ?? 0;
            infoSecundaria =
              dadosHome.Cards.PedidosNaoEntregues.ComparadoMesAnterior ?? 0;
          } else {
            infoPrincipal = dadosHome.Cards.ClientesNovos ?? 0;
          }
          return (
            <StatisticsCard
              key={title}
              {...rest}
              value={infoPrincipal}
              title={title}
              icon={React.createElement(icon, {
                className: "w-6 h-6 text-white",
              })}
              footer={
                <Typography className="font-normal text-blue-gray-600">
                  {infoSecundaria == null ? null : (
                    <strong className={footer.color}>{infoSecundaria}</strong>
                  )}
                  &nbsp;{infoSecundaria == null ? "" : footer.label}
                </Typography>
              }
              label={footer.value}
            />
          );
        })}
      </div>
      <div className="mb-6 grid grid-cols-1 gap-y-12 gap-x-6 md:grid-cols-2 xl:grid-cols-3">
        {statisticsChartsData.map((props) => {
          var chart = props.chart;
          if (props.index == 0) {
            var categories = (
              dadosHome.Graficos.EntregasPorDiaSemana ?? []
            ).map((item) => item.Data);
            var dados = (dadosHome.Graficos.EntregasPorDiaSemana ?? []).map(
              (item) => item.Total
            );

            chart = {
              type: "bar",
              height: 220,
              series: [
                {
                  name: "Pedidos",
                  data: dados ?? [],
                },
              ],
              options: {
                ...chartsConfig,
                colors: "#fff",
                plotOptions: {
                  bar: {
                    columnWidth: "16%",
                    borderRadius: 5,
                  },
                },
                xaxis: {
                  ...chartsConfig.xaxis,
                  categories: categories ?? [],
                },
              },
            };

            props.chart = chart;
          }
          if (props.index == 1) {
            var categories = dadosHome.Graficos.SlaEntrega.map(
              (item) => item.Data
            );
            var dados = dadosHome.Graficos.SlaEntrega.map((item) => item.Total);
            chart = {
              type: "line",
              height: 220,
              series: [
                {
                  name: "SLA",
                  data: dados ?? [],
                },
              ],
              options: {
                ...chartsConfig,
                colors: ["#fff"],
                stroke: {
                  lineCap: "round",
                },
                markers: {
                  size: 5,
                },
                xaxis: {
                  ...chartsConfig.xaxis,
                  categories: categories ?? [],
                },
              },
            };
            props.chart = chart;
          }
          if (props.index == 2) {
            var categories = dadosHome.Graficos.Ocorrencias.map(
              (item) => item.Descricao
            );

            var dados = dadosHome.Graficos.Ocorrencias.map((item) => {
              return {
                x: item.Descricao,
                y: item.Total,
                fillColor: item.FillColor,
              };
            });

            chart = {
              type: "bar",
              height: 220,

              options: {
                ...chartsConfig,
                colors: ["#fff"],
                dataLabels: {
                  enabled: false,
                },
                animations: {
                  enabled: false,
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                    borderRadius: 4,
                  },
                },

                chart: {
                  height: 350,
                  type: "line",
                  zoom: {
                    enabled: false,
                  },
                  animations: {
                    enabled: false,
                  },
                  toolbar: {
                    show: true,
                    tools: {
                      download: false,
                    },
                  },
                },

                stroke: {
                  lineCap: "round",
                },
                markers: {
                  size: 5,
                },
                xaxis: {
                  categories: categories ?? [],
                },
              },

              series: [
                {
                  name: "Ocorrências",
                  data: dados ?? [],
                },
              ],
            };
            props.chart = { ...chart };
          }

          return (
            <StatisticsChart
              key={props.title}
              background={props.background}
              {...props}
              footer={
                <Typography
                  variant="small"
                  className="flex items-center font-normal text-blue-gray-600"
                >
                  <ClockIcon strokeWidth={2} className="h-4 w-4 text-inherit" />
                  &nbsp;{props.footer}
                </Typography>
              }
            />
          );
        })}
      </div>
      <div className="mb-8 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3"></div>

      <div className="mb-4 grid grid-cols-1 gap-6 xl:grid-cols-3">
        <Card className="overflow-hidden xl:col-span-2 ">
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 flex items-center justify-between p-6"
          >
            <div>
              <Typography variant="h6" color="blue-gray" className="mb-3">
                Principais Clientes
              </Typography>
              {/* <Typography
                variant="small"
                className="flex items-center gap-1 font-normal text-blue-gray-600"
              >
                <CheckIcon strokeWidth={3} className="h-4 w-4 text-blue-500" />
                <strong>pedidos</strong> deste mês
              </Typography> */}
            </div>
          </CardHeader>
          <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
            <table className="w-full min-w-[640px] table-auto">
              <thead>
                <tr>
                  {["NOME DO CLIENTE", "BAIRRO", "PEDIDOS"].map((el) => (
                    <th
                      key={el}
                      className="border-b border-blue-gray-50 py-3 px-6 text-left"
                    >
                      <Typography
                        variant="small"
                        className="text-[11px] font-medium uppercase text-blue-gray-400"
                      >
                        {el}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dadosHome.Informacoes.PrincipaisClientes.map(
                  ({ Nome, Bairro, Pedidos }, key) => {
                    const className = `py-3 px-5 ${
                      key ===
                      dadosHome.Informacoes.PrincipaisClientes.length - 1
                        ? ""
                        : "border-b border-blue-gray-50 "
                    }`;
                    return (
                      <tr key={Nome + Bairro + Pedidos}>
                        <td className={className}>
                          <div className="flex items-center gap-4">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold"
                            >
                              {Nome}
                            </Typography>
                          </div>
                        </td>
                        <td className={className}>
                          <div className="flex items-center gap-4">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold"
                            >
                              {Bairro ?? "-"}
                            </Typography>
                          </div>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            className="text-xs font-medium text-blue-gray-600 text-center"
                          >
                            {Pedidos}
                          </Typography>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </CardBody>
        </Card>

        <CardPerfilLoja
        // imagen={"https://cdn-icons-png.flaticon.com/512/3135/3135768.png"}
        // nomeLoja={"sp RD RIBEIRAO PRETO (FL2009)"}
        // endereco={
        //   "R. Catarina Aparecida Navis Silva, 246 - Jardim Nova Alianca, Ribeirão Preto - SP, 14026-565"
        // }

        // imagen={infoLoja.Imagem}
        // nomeLoja={infoLoja.Nome}
        // endereco={infoLoja.Endereco}
        // linkEndereco={
        //   "https://www.google.com/maps/search/" +
        //   infoLoja.Latitude +
        //   "," +
        //   infoLoja.Longitude
        // }
        />
      </div>
    </div>
  );
}

export default Home;
