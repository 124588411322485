import { BanknotesIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { Button, Card } from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useCodigoLocal from "../../hooks/useCodigoLocal";
import useEditPedido from "../../hooks/useEditPedido";
import useIdLocal from "../../hooks/useIdLocal";
import useKM from "../../hooks/useKM";
import useToken from "../../hooks/useToken";
import { ApiPedidos } from "../../services/pedidos";
import urlLsFast from "../../utils/globalVars";
import verificarHoraFuncionamento from "../../utils/verificar_hora_funcionamento";
import { NovoPedidoCards } from "../../widgets/cards";
import { DropdownComponent, TextField } from "../../widgets/inputs";
import Row from "../../widgets/layout/row";
export function CadastroPedidoReceita() {
  const navigate = useNavigate();
  const { token, setToken } = useToken();
  var { idLocal, setIdLocal } = useIdLocal();
  var { km, setKM } = useKM();
  var { codigoLocal, setCodigoLocal } = useCodigoLocal();
  var { pedidoCompleto, setPedidoCompleto } = useEditPedido();

  var [tab, setTab] = useState("endereco");
  var [cep, setCep] = useState("");
  var [endereco, setEndereco] = useState("");
  var [numero, setNumero] = useState("");
  var [complemento, setComplemento] = useState("");
  var [pontoReferencia, setPontoReferencia] = useState("");
  var [bairro, setBairro] = useState("");
  var [cidade, setCidade] = useState("");
  var [uf, setUf] = useState("SP");
  var [nomeCliente, setNomeCliente] = useState("");
  var [valorReceber, setValorReceber] = useState(0);
  var [telefone, setTelefone] = useState("");
  var [horario, setHorario] = useState("");
  var [isreceita, setIsreceita] = useState(true);

  var [pedido, setPedido] = useState("");
  var [isValorDaCorrida, setIsValorDaCorrida] = new useState(false);
  var [ishorario, setIshorario] = new useState(false);
  var [erroBuscarCep, setErroBuscarCep] = useState(true);

  const numeroRef = useRef(null);
  const complementoRef = useRef(null);
  const pontoReferenciaRef = useRef(null);
  const telefoneRef = useRef(null);
  const valorRef = useRef(null);
  const horarioRef = useRef(null);

  useEffect(() => {
    if (pedidoCompleto != null) {
      var os = pedidoCompleto.os;
      if (os.includes("-") == true) {
        var dados = os.split("-");
        dados.shift();
        os = dados.join("-");
      }
      setCep(pedidoCompleto.cep);
      setPedido(os);
      setIsValorDaCorrida(pedidoCompleto.receberValor);
      setIshorario(pedidoCompleto.alterarHorario);
      setNumero(pedidoCompleto.numero);
      setEndereco(pedidoCompleto.endereco);
      setBairro(pedidoCompleto.bairro);
      setCidade(pedidoCompleto.cidade);
      setUf(pedidoCompleto.uf);
      setNomeCliente(pedidoCompleto.clienteNome);
      setTelefone(pedidoCompleto.telefone);
      setValorReceber(pedidoCompleto.valorReceber);
      setHorario(pedidoCompleto.horario);
      setPontoReferencia(pedidoCompleto.pontoReferencia);
      setComplemento(pedidoCompleto.complemento);
      setIsValorDaCorrida(pedidoCompleto.receberValor);
      setIshorario(pedidoCompleto.alterarHorario);
      setIsreceita(pedidoCompleto.receita);
      setPedidoCompleto(null);
      setTab("entrega");
    }
  });

  async function buscarCep(cep) {
    try {
      if (cep.length < 9) {
        return;
      }
      const url = urlLsFast + "/servicos/buscacep?cep=" + cep;
      const response = await fetch(url, {
        method: "GET",
      });
      const data = await response.json();

      if (data == null) {
        Swal.fire("Erro", "Ocorreu um erro!", "error");
      } else {
        setEndereco(data["Logradouro"]);
        setBairro(data["Bairro"]);
        setCidade(data["Cidade"]);
        setUf(data["Estado"]);
        numeroRef.current.focus();
      }
      setErroBuscarCep(true)
    } catch (error) {
      Swal.fire("CEP", "CEP não encontrado digite o endereço da entrega", "info");
      setErroBuscarCep(false);
    }
  }

  async function addPedido() {
    var novoHorario = null;
    if (ishorario) {
      var data = new Date();
      data = new Date(data.getTime());
      var ano = data.getFullYear();
      var mes = data.getMonth() + 1;
      var dia = data.getDate();
      // var hora = data.getHours() + 1;
      // var minuto = data.getMinutes();
      // var stringData = `${ano}-${"0".repeat(mes < 10 ? 1 : 0) + mes}-${
      //   "0".repeat(dia < 10 ? 1 : 0) + dia
      // }T${"0".repeat(hora < 10 ? 1 : 0) + hora}:${
      //   "0".repeat(minuto < 10 ? 1 : 0) + minuto
      // }:00.000`;

      var horarioNumeros = horario.replace(/[^0-9]/g, "");
      var tamanho = horarioNumeros.length;
      if (tamanho < 4) {
        horarioNumeros = horarioNumeros + "0".repeat(4 - tamanho);
      }
      var newHorarioNumeros =
        horarioNumeros.substring(0, 2) + ":" + horarioNumeros.substring(2);
      novoHorario = `${ano}-${"0".repeat(mes < 10 ? 1 : 0) + mes}-${
        "0".repeat(dia < 10 ? 1 : 0) + dia
      }T${newHorarioNumeros}:00.000`;
      // if (Date.parse(novoHorario) < Date.parse(stringData)) {
      //   Swal.fire("Erro", "Horário inválido", "error");
      //   return;
      // }
    }
    var novoValorCorrida = null;
    if (isValorDaCorrida) {
      novoValorCorrida = valorReceber;
    }
    const body = {
      IDLocal: idLocal,
      IDTipo: 23,
      Cep: cep,
      Endereco: endereco,
      Numero: numero,
      Complemento: complemento,
      Referencia: pontoReferencia,
      Bairro: bairro,
      Cidade: cidade,
      UF: uf,
      Telefone: telefone,
      Pedido: `REC${codigoLocal}-${pedido}`,
      Valor: novoValorCorrida ?? 0,
      NomeCliente: nomeCliente,
      NotaFiscal: `${codigoLocal}-${pedido}`,
      ValorCorrida: novoValorCorrida ?? 0,
      Horario: novoHorario,
      Receita: isreceita,
    };
    console.log(body);
    var response = await ApiPedidos.criarNovo(token, body);

    if (response == "") {
      setPedido(null);
      navigate("/dashboard/pedidos");
    } else {
      Swal.fire("Erro", response, "error");
    }
  }
  async function validarCampos() {
    if (cep == "") {
      Swal.fire("Erro", "CEP inválido", "error");
      return false;
    }

    // var latlng = await getLatLngByCep(cep, idLocal);

    // if (latlng == null) {
    //   Swal.fire("Erro", "Localização nao encontrada", "error");
    //   return false;
    // }

    // var kmCalc = await distanceCalculator(
    //   latlng["Latitude"],
    //   latlng["Longitude"],
    //   latlng["LocalLatitude"],
    //   latlng["LocalLongitude"]
    // );

    // if (km < kmCalc) {
    //   Swal.fire(
    //     "Erro",
    //     `O endereço da entrega não está dentro da área de cobertura. A distância maxima de ${km} km`,
    //     "error"
    //   );
    //   return false;
    // }

    // if (endereco == "") {
    //   Swal.fire("Erro", "Endereço inválido", "error");
    //   return false;
    // }

    if (numero == "") {
      Swal.fire("Erro", "Número inválido", "error");
      return false;
    }

    if (cidade == "") {
      Swal.fire("Erro", "Cidade inválida", "error");
      return false;
    }

    if (uf == "") {
      Swal.fire("Erro", "UF inválida", "error");
      return false;
    }

    if (nomeCliente == "") {
      Swal.fire("Erro", "Nome do cliente inválido", "error");
      return false;
    }
    if (telefone == "") {
      Swal.fire("Erro", "Telefone inválido", "error");
      return false;
    }

    if (pedido == "") {
      Swal.fire("Erro", "Pedido inválido", "error");
      return false;
    }

    if (isValorDaCorrida) {
      if (valorReceber == "") {
        Swal.fire("Erro", "Valor a receber inválido", "error");
        return false;
      }
    }
    if (ishorario) {
      var isveryhora = await verificarHoraFuncionamento(token, horario);
      if (isveryhora == false) {
        return false;
      }
    }
    return true;
  }

  var data = [
    {
      label: "Endereço",
      value: "endereco",
    },
    {
      label: "Cliente",
      value: "cliente",
    },
    {
      label: "Pedido",
      value: "pedido",
    },
    {
      label: "Coleta",
      value: "entrega",
    },
  ];

  const dataCards = [
    {
      color: "blue",
      icon: BanknotesIcon,
      title: "Endereço de Coleta",
      values: [
        { title: "Rua", text: `${endereco}, ${numero}` },
        { title: "Complemento", text: complemento },
        { title: "Referência", text: pontoReferencia },
        { title: "Bairro", text: bairro },
        { title: "Cidade", text: `${cidade}/${uf}` },
      ],
    },
    {
      color: "green",
      icon: UserPlusIcon,
      title: "Dados do Cliente",
      values: [
        { title: "Nome", text: nomeCliente },
        { title: "Telefone", text: telefone },
      ],
    },
  ];

  var infoPrincipal = "new";

  return (
    <>
      <Card>
        <div className="m-4">
          <Row>
            {data.map(({ label, value }) => {
              return (
                <div className="w-full">
                  <Button
                    onClick={() => setTab(value)}
                    style={{
                      backgroundColor: tab == value ? "#FFFFFF" : "#ECEFF1",
                      boxShadow:
                        tab == value ? "2px 2px 4px #ECEFF1" : "0 0 0 0",
                    }}
                    className="w-full"
                  >
                    <div
                      style={{
                        color: "black",
                      }}
                    >
                      {label}
                    </div>
                  </Button>
                </div>
              );
            })}
          </Row>
          {tab === "endereco" && (
            <>
              <div className="h-5" />
              <Row>
                <TextField
                  autoFocus={true}
                  cep={true}
                  value={cep}
                  titulo="CEP do cliente"
                  onChange={async (a) => {
                    setCep(a.target.value);
                    await buscarCep(a.target.value);
                  }}
                />
                <TextField
                  titulo="Endereço"
                  value={endereco}
                  onChange={(a) => {
                    setEndereco(a.target.value);
                  }}
                  disabled={erroBuscarCep}
                />
              </Row>
              <Row>
                <TextField
                  ref={numeroRef}
                  nextRef={() => {
                    complementoRef.current.focus();
                  }}
                  titulo="Número"
                  value={numero}
                  maxLength="50"
                  onChange={(a) => {
                    setNumero(a.target.value);
                  }}
                />
                <TextField
                  ref={complementoRef}
                  nextRef={() => {
                    pontoReferenciaRef.current.focus();
                  }}
                  titulo="Complemento"
                  value={complemento}
                  maxLength="250"
                  onChange={(a) => {
                    setComplemento(a.target.value);
                  }}
                />
              </Row>
              <Row>
                <TextField
                  ref={pontoReferenciaRef}
                  titulo="Ponto de referência"
                  value={pontoReferencia}
                  maxLength="150"
                  onChange={(a) => {
                    setPontoReferencia(a.target.value);
                  }}
                />
                <TextField
                  titulo="Bairro"
                  value={bairro}
                  onChange={(a) => {
                    setBairro(a.target.value);
                  }}
                  disabled={erroBuscarCep}
                />
              </Row>
              <Row>
                <TextField
                  titulo="Cidade"
                  value={cidade}
                  onChange={(a) => {
                    setCidade(a.target.value);
                  }}
                  disabled={erroBuscarCep}
                />
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <DropdownComponent
                    disabled={erroBuscarCep}
                    titulo="UF"
                    value={uf}
                    dados={[
                      "AC",
                      "AL",
                      "AP",
                      "AM",
                      "BA",
                      "CE",
                      "DF",
                      "ES",
                      "GO",
                      "MA",
                      "MT",
                      "MS",
                      "MG",
                      "PA",
                      "PB",
                      "PR",
                      "PE",
                      "PI",
                      "RJ",
                      "RN",
                      "RS",
                      "RO",
                      "RR",
                      "SC",
                      "SP",
                      "SE",
                      "TO",
                    ]}
                    onChange={setUf}
                  />
                </div>
              </Row>

              <div className="h-5" />
              <Row>
                <div className="w-full"></div>
                <div className="w-full"></div>

                <Button
                  className="w-full mx-2"
                  onClick={() => {
                    setTab("cliente");
                  }}
                >
                  Proximo
                </Button>
              </Row>
            </>
          )}
          {tab === "cliente" && (
            <>
              <div className="h-5" />
              <Row>
                <TextField
                  nextRef={() => {
                    telefoneRef.current.focus();
                  }}
                  autoFocus={true}
                  titulo="Nome e Sobrenome do Cliente"
                  maxLength="255"
                  value={nomeCliente}
                  onChange={(a) => {
                    setNomeCliente(a.target.value);
                  }}
                />
                <TextField
                  ref={telefoneRef}
                  titulo="Telefone"
                  telefone={true}
                  value={telefone}
                  maxLength="50"
                  onChange={(a) => {
                    setTelefone(a.target.value);
                  }}
                />
              </Row>
              <div className="h-5"></div>
              <Row>
                <Button
                  className="w-full mx-2"
                  onClick={() => {
                    setTab("endereco");
                  }}
                >
                  Voltar
                </Button>
                <div className="w-full"></div>
                <Button
                  className="w-full mx-2"
                  onClick={() => {
                    setTab("pedido");
                  }}
                >
                  Proximo
                </Button>
              </Row>
            </>
          )}
          {tab === "pedido" && (
            <>
              <div className="h-5" />
              <Row>
                <div style={{ width: 100 }}>
                  <TextField
                    value={"REC" + codigoLocal}
                    disabled={true}
                    width={100}
                  />
                </div>
                <TextField
                  autoFocus={true}
                  titulo="Cupom ou número da receita"
                  value={pedido}
                  maxLength={(
                    50 -
                    (codigoLocal.toString().length + 1)
                  ).toString()}
                  onChange={(a) => {
                    setPedido(a.target.value);
                  }}
                />
              </Row>
              <div className="h-2"></div>
              <Row>
                <div
                  className="w-full"
                  style={{
                    marginLeft: 5,
                  }}
                >
                  {/* <Row>
                    <div>
                      <Switch
                        id="ishorario"
                        titulo="agendar para outro horário?"
                        value={ishorario}
                        checked={ishorario}
                        onChange={(a) => {
                          setIshorario(a.target.checked);
                          if (a.target.checked) {
                            setTimeout(() => {
                              horarioRef.current.focus();
                            }, 100);
                          }
                        }}
                      />
                    </div>
                    <th scope="col" className="text-xs text-left text-gray-500">
                      Desejar informar o horário que o agente deverá passar no
                      cliente?
                    </th>
                  </Row> */}
                </div>
              </Row>
              <div className="h-2"></div>
              {/* <Row>
                <TextField
                  ref={horarioRef}
                  titulo="Informar horário de coleta no cliente:"
                  disabled={!ishorario}
                  horario={true}
                  value={horario}
                  onChange={(a) => {
                    setHorario(a.target.value);
                  }}
                />
                <div
                  style={{
                    width: "80%",
                  }}
                ></div>
              </Row> */}
              <div className="h-5"></div>
              <Row>
                <Button
                  className="w-full mx-2"
                  onClick={() => {
                    setTab("cliente");
                  }}
                >
                  Voltar
                </Button>
                <div className="w-full"></div>
                <Button
                  className="w-full mx-2"
                  onClick={() => {
                    setTab("entrega");
                  }}
                >
                  Proximo
                </Button>
              </Row>
            </>
          )}
          {tab === "entrega" && (
            <>
              <div className="h-5" />
              <Row>
                {dataCards.map(({ index, icon, title, footer, ...rest }) => {
                  return (
                    <div className="w-full">
                      <NovoPedidoCards
                        key={title}
                        {...rest}
                        value={infoPrincipal}
                        title={title}
                        icon={React.createElement(icon, {
                          className: "w-6 h-6 text-white",
                        })}
                      />
                    </div>
                  );
                })}
              </Row>
              <div className="h-10" />
              <Row>
                <Button
                  className="w-full "
                  onClick={() => {
                    setTab("pedido");
                  }}
                >
                  Voltar
                </Button>
                <div className="w-full"></div>
                <Button
                  className="w-full mx-2 bg-green-500 hover:bg-green-600"
                  onClick={async () => {
                    var very = await validarCampos();
                    if (very) {
                      addPedido();
                    }
                  }}
                >
                  Confirmar Coleta
                </Button>
              </Row>
            </>
          )}
        </div>
      </Card>
    </>
  );
}

export default CadastroPedidoReceita;
