import React from "react"

function Row(props) {
  return (
    <div className="w-full">
      <div style={{ display: "flex", flexDirection: "row" }} className="gap-2">
        {props.children}
      </div>
    </div>
  )
}

export default Row
