import PedidoModel from "../../models/pedidoModel";
import urlLsFast from "../../utils/globalVars";

export class ApiPedidos {
  static convertDateTime(value, completo = false) {
    if ((value ?? "").trim() == "") return "";
    if (completo) {
      var data = value.split("T")[0].split("-");
      var hora = value.substring(11, 16);
      return data[2] + "/" + data[1] + "/" + data[0] + " " + hora;
    }
    return value.substring(11, 16);
  }

  static async load(token) {
    try {
      const url = urlLsFast + "/pedido/buscar";
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: token,
        },
      });
      if (response.status === 200) {
        const json = await response.json();

        var pedidos = json.map((item) => {
          return new PedidoModel(
            item["ID"],
            item["OS"],
            ApiPedidos.convertDateTime(item["DataEntrada"], true),
            item["Loja"],
            item["ClienteNome"] ?? "Consumidor final",
            item["EnderecoCliente"] ?? "Endereço não informado",
            item["ValorReceber"] ?? 0,
            item["EntregadorNome"] ?? "",
            (item["FotoEntregador"] ?? "") == ""
              ? "https://www.w3schools.com/howto/img_avatar.png"
              : item["FotoEntregador"],
            ApiPedidos.convertDateTime(item["ChegadaLoja"]),
            ApiPedidos.convertDateTime(item["EntregaPedido"]),
            item["Frete"] ?? 0,
            item["StatusPedido"] ?? "Status não informado",
            item["ModalidadeEntrega"] ?? "--",
            ApiPedidos.convertDateTime(item["Horario"], true),
            item["Deletar"] ?? false,
            item["Reentrega"] ?? false,
            item["Cep"],
            item["Endereco"],
            item["Numero"],
            item["Complemento"],
            item["PontoReferencia"],
            item["Bairro"],
            item["UF"],
            item["Cidade"],
            item["Telefone"],
            item["ReceberValor"],
            item["AlterarHorario"],
            item["Receita"] ?? false,
            ApiPedidos.convertDateTime(item["DataSLA"]),
            ApiPedidos.convertDateTime(item["DataTermino"])
          );
        });

        return pedidos;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async criarNovo(token, body) {
    console.log(body);
    try {
      console.log(token);
      const url = urlLsFast + "/pedido/gerar";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.ok) {
        return "";
      } else {
        const errorResponse = await response.json();
        const errorMessage = errorResponse.error;
        return errorMessage;
      }
    } catch (error) {
      return "Ocorreu um erro ao enviar a solicitação.";
    }
  }

  static async gerarEtiquetas(ids) {
    console.log(ids);
    var url = urlLsFast + "/pedido/gerar-relatorio?ids=";
    url += ids.join("&ids=");
    console.log(url);
    // window.open(url, "_blank");

    const response = await fetch(url);
    const htmlString = await response.text();

    const newWindow = window.open("", "_blank");
    newWindow.document.write(htmlString);
    newWindow.document.close();
  }
}

export default ApiPedidos;
