export function formatDate(date) {
  //converter data para o formato dd/mm/yyyy Hh:mm
  try {
    const dateArray = date.split("T")[0].split("-");
    const timeArray = date.split("T")[1].split(":");
    const day = dateArray[2];
    const month = dateArray[1];
    const year = dateArray[0];
    const hour = timeArray[0];
    const minute = timeArray[1];
    return `${day}/${month}/${year} ${hour}:${minute}`;
  } catch (error) {
    return "";
  }
}

export default formatDate;
