export class StatusAtividadeModel {
  constructor(json) {
    this.statusAtividades = json.map((a) => {
      return new StatusAtividade(a);
    });
  }
}

class StatusAtividade {
  constructor(json) {
    this.ID = json.ID;
    this.Nome = json.Nome;
  }
}
export const jsonDefaultStatusAtividadeModel = [];

export default StatusAtividadeModel;
