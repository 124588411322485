import { useState } from "react";
import Datepicker from "tailwind-datepicker-react";

export function DatePickerCustom({ onChange, defaultDate }) {
  console.log(defaultDate);
  var options = {
    autoHide: true,
    todayBtn: false,
    clearBtn: false,
    maxDate: new Date("2130-01-01"),
    minDate: new Date("1950-01-01"),
    theme: {
      background: "bg-gray-100",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "text-black",
      disabledText: "bg-gray",
      input: " bg-gray-100",
      inputIcon: "",
      selected: "bg-blue-200",
    },
    icons: {
      prev: () => <span>{"<"}</span>,
      next: () => <span>{">"}</span>,
    },
    datepickerClassNames: "top-12",
    defaultDate: defaultDate ?? new Date(),
    language: "pt",
  };

  const [show, setShow] = useState(false);

  return (
    <div className="w-full c">
      <Datepicker
        options={options}
        show={show}
        onChange={(date) => {
          onChange(date);
        }}
        setShow={(state) => {
          setShow(state);
        }}
      />
      <div className="h-2"></div>
    </div>
  );
}

export default DatePickerCustom;
