import { Button, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useEditPedido from "../../hooks/useEditPedido";
import useReceita from "../../hooks/useReceita";
import useToken from "../../hooks/useToken";
import { ApiPedidos } from "../../services/pedidos";
import urlLsFast from "../../utils/globalVars";
import Row from "../../widgets/layout/row";
import InfoPedidoCard from "../../widgets/pedidos/infoPedidoCard";
import ItemList from "../../widgets/popup/item_popup";
import Modal from "../../widgets/popup/popup_modal";

export function AgendamentoEntrega() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pedidos, setPedidos] = useState([]);
  const { token } = useToken();
  const { setPedidoCompleto } = useEditPedido();
  const { receita } = useReceita();
  const navigate = useNavigate();

  const handleCheckboxChange = (e, item) => {
    if (e.target.checked) {
      setSelectedItems((prevItems) => [...prevItems, item]);
    } else {
      setSelectedItems((prevItems) => prevItems.filter((i) => i !== item));
    }
  };

  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => {
    if (selectedItems.length > 0) {
      console.log(selectedItems);
      ApiPedidos.gerarEtiquetas(selectedItems);
    }
    setSelectedItems([]);
    setIsOpen(false);
  };

  const loadPedidos = async () => {
    setIsLoading(true);
    try {
      const newPedidos = await ApiPedidos.load(token);
      setPedidos(newPedidos);
    } catch (error) {
      console.error("Failed to load pedidos", error);
    } finally {
      setIsLoading(false);
    }
  };

  const removePedido = async (pedido) => {
    const url = `${urlLsFast}/pedido/delete?idos=${pedido.id}&cancelar=false`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: { token: token },
    });

    if (response.ok) {
      loadPedidos();
    }

    return response;
  };

  useEffect(() => {
    loadPedidos();
  }, []); // Removed loadInicial logic for simplicity

  return (
    <div>
      <Row>
        {/* Conditional rendering for Link components */}
        {!isOpen && (
          <>
            <Link
              to="/dashboard/cadastro/pedido"
              onClick={() => setPedidoCompleto(null)}
            >
              <Button>novo pedido</Button>
            </Link>
            {receita && (
              <Link
                to="/dashboard/cadastro/pedido/receita"
                onClick={() => setPedidoCompleto(null)}
              >
                <Button style={{ backgroundColor: "#2f855a" }}>
                  COLETA DE RECEITA
                </Button>
              </Link>
            )}
          </>
        )}
        <div style={{ flexGrow: 1 }} />
        <Button
          style={{
            backgroundColor:
              !isOpen || selectedItems.length > 0 ? "#dd6b20" : "#a0aec0",
          }}
          onClick={isOpen ? handleCloseModal : handleOpenModal}
        >
          {isOpen ? "IMPRIMIR SELECIONADAS" : "IMPRIMIR ETIQUETAS"}
        </Button>
      </Row>
      {isLoading ? (
        <div className="flex justify-center items-center h-96">
          <div
            className="w-full flex items-center justify-center"
            style={{ height: "5.5rem" }}
          >
            <div
              className="inline-block h-10 w-10 animate-spin rounded-full border-4 border-solid border-orange-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="absolute -m-px h-px w-px overflow-hidden whitespace-nowrap border-0 p-0 clip-rect(0,0,0,0)">
                LoadingExcel...
              </span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Modal isOpen={isOpen} onClose={handleCloseModal}>
            <ItemList
              items={pedidos}
              handleCheckboxChange={handleCheckboxChange}
            />
          </Modal>
          {isOpen || pedidos.length > 0 ? (
            pedidos.map((pedido) => (
              <InfoPedidoCard
                key={pedido.id}
                pedido={pedido}
                editar={() => {
                  setPedidoCompleto(pedido);
                  navigate(
                    pedido.receita
                      ? "/dashboard/cadastro/pedido/receita"
                      : "/dashboard/cadastro/pedido"
                  );
                }}
                excluir={() => removePedido(pedido)}
              />
            ))
          ) : (
            <div className="flex justify-center items-center">
              <Typography variant="small">
                Nenhum pedido encontrado...
              </Typography>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default AgendamentoEntrega;
