import urlLsFast from "../../utils/globalVars";

export async function buscarInformacoesDaLoja(token, idLocal, setInfoLoja) {
  console.log("Buscando informações da loja");
  console.log("Token: " + token);
  console.log("ID Local: " + idLocal);

  // [HttpGet()]
  //      [Route("informacoes-loja")]
  //      public IActionResult BuscarInformacoesLoja([FromHeader] String token, [FromQuery] int IDLocal)
  //      {
  const response = await fetch(
    urlLsFast + "/dashboard/informacoes-loja" + "?IDLocal=" + idLocal,
    {
      headers: {
        accept: "*/*",
        token: token,
      },
    }
  );

  const data = await response.json();
  setInfoLoja(data);

  return data;
}

export default buscarInformacoesDaLoja;
