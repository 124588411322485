import urlLsFast from "../../utils/globalVars";

export async function LoginExternal(login, senha) {
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 3000);
    const url = urlLsFast + "/autenticacao/login";
    const data = {
      login: login,
      senha: senha,
    };
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    clearTimeout(timeoutId);
    if (response.status === 200) {
      const json = await response.json();
      return json;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

export default LoginExternal;
