import urlLsFast from "../../utils/globalVars";

class ApiRelatorio {
  static async relatorioConsut(
    token,
    cliente,
    pedido,
    StatusAtividade,
    startDate,
    endDate
  ) {
    var dataInicio = startDate.toISOString();
    var dataTermino = endDate.toISOString();
    try {
      const url = urlLsFast + "/relatorio/buscar";
      var params = {
        Cliente: cliente,
        Pedido: pedido,
        IDAgente: "",
        StatusAtividade: StatusAtividade,
        DataInicio: dataInicio,
        DataTermino: dataTermino,
      };
      const response = await fetch(url + "?" + new URLSearchParams(params), {
        method: "GET",
        headers: {
          accept: "*/*",
          Token: token,
        },
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
export default ApiRelatorio;
