export class DadosHome {
  constructor(json) {
    this.Cards = new Cards(json["Cards"]);
    this.Graficos = new Graficos(json["Graficos"]);
    this.Informacoes = new Informacoes(json["Informacoes"]);
  }
}

class Informacoes {
  constructor(json) {
    this.PrincipaisClientes = json["PrincipaisClientes"].map(
      (item) => new PrincipaisClientes(item)
    );
    this.PrincipaisEntregadores = json["PrincipaisEntregadores"].map(
      (item) => new PrincipaisEntregadores(item)
    );
  }
}

class PrincipaisClientes {
  constructor(json) {
    this.Nome = json["Nome"] || "Sem nome cadastrado";
    this.Bairro = json["Bairro"];
    this.Pedidos = json["Pedidos"];
  }
}

class Cards {
  constructor(json) {
    this.PedidosMes = new PedidosMes(json["PedidosMes"]);
    this.PedidosNaoEntregues = new PedidosNaoEntregues(
      json["PedidosNaoEntregues"]
    );
    this.ClientesNovos = json["ClientesNovos"];
  }
}

class PedidosMes {
  constructor(json) {
    this.PedidosMes = json["PedidosMes"];
    this.ComparadoMesAnterior = json["ComparadoMesAnterior"];
  }
}

class PedidosNaoEntregues {
  constructor(json) {
    this.PedidosNaoEntregues = json["PedidosNaoEntregues"];
    this.ComparadoMesAnterior = json["ComparadoMesAnterior"];
  }
}

class Graficos {
  constructor(json) {
    this.EntregasPorDiaSemana = json["EntregasPorDiaSemana"].map(
      (item) => new EntregasPorDiaSemana(item)
    );

    this.SlaEntrega = json["SlaEntrega"].map((item) => new SlaEntrega(item));

    this.Ocorrencias = json["Ocorrencias"].map((item) => new Ocorrencias(item));
  }
}

class EntregasPorDiaSemana {
  constructor(json) {
    this.Total = json["Total"];
    if (json["Data"] != null) {
      var data = json["Data"].split("/");
      data = new Date(data[2], data[1] - 1, data[0]);
      const diaDaSemanaNum = data.getDay();
      const diasDaSemana = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
      const diaDaSemanaNome = diasDaSemana[diaDaSemanaNum];
      this.Data = diaDaSemanaNome;
    } else {
      this.Data = "";
    }
  }
}

class SlaEntrega {
  constructor(json) {
    if (json["Data"] != null) {
      var data = json["Data"].split("/");
      data = new Date(data[2], data[1] - 1, data[0]);
      const diaDaSemanaNum = data.getDay();
      const diasDaSemana = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
      const diaDaSemanaNome = diasDaSemana[diaDaSemanaNum];
      this.Data = diaDaSemanaNome;
    }
    var data;
    if (json["Data"] != null) {
      data = json["Data"].split("/")[0];
    }
    this.Total = String(json["Total"]);
  }
}

class Ocorrencias {
  constructor(json) {
    var fillColor = "#FF6384";

    switch (String(json["Descricao"])) {
      case "Endereço Não Localizado":
        fillColor = "#fff730";
        break;
      case "Cliente Ausente":
        fillColor = "#36A2EB";
        break;
      case "Cancelado pelo cliente":
        fillColor = "#FFCD56";
        break;
      case "Numero não localizado":
        fillColor = "#1B713B";
        break;
      case "Pedido Extraviado":
        fillColor = "#32f7ff";
        break;
      case "Pedido Recusado":
        fillColor = "#a0a1a2";
        break;
      case "Cliente Inexistente":
        fillColor = "#546dff";
        break;
      case "Pedido Danificado":
        fillColor = "#38ff53";
        break;
      default:
        fillColor = "#a8ddff";
        break;
    }
    this.Total = json["Total"];
    this.Descricao = json["Descricao"];
    this.FillColor = fillColor;
  }
}

class PrincipaisEntregadores {
  constructor(json) {
    this.Foto =
      json["Foto"] || "https://www.w3schools.com/howto/img_avatar.png";
    this.Nome = json["Nome"];
    this.Disponivel = json["Disponivel"];
  }
}

export const jsonDefaultDadosHome = {
  Cards: {
    PedidosMes: [
      {
        PedidosMes: 0,
        ComparadoMesAnterior: 0,
      },
    ],
    PedidosNaoEntregues: {
      PedidosNaoEntregues: 0,
      ComparadoMesAnterior: 0,
    },
    ClientesNovos: 0,
  },
  Graficos: {
    EntregasPorDiaSemana: [
      {
        Total: 0,
        Data: null,
      },
    ],
    SlaEntrega: [
      {
        Total: 0,
        Data: null,
      },
    ],
    Ocorrencias: [
      {
        Total: 0,
        Descricao: "",
      },
    ],
  },
  Informacoes: {
    PrincipaisClientes: [
      {
        Nome: [],
        Bairro: [],
        Pedidos: [],
      },
    ],
    PrincipaisEntregadores: [
      {
        Foto: [],
        Nome: [],
        Pedidos: [],
      },
    ],
  },
};

export default DadosHome;
