import { useState } from "react";

export default function useReceita() {
  function getReceita() {
    const valueString = sessionStorage.getItem("coletarReceita");
    return valueString === "true";
  }

  const [receita, setReceita] = useState(getReceita());

  const saveReceita = (userToken) => {
    sessionStorage.setItem("coletarReceita", userToken);
    setReceita(userToken);
  };

  return {
    setReceita: saveReceita,
    receita,
  };
}
