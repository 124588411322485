import { Option, Select } from "@material-tailwind/react";
import PropTypes from "prop-types";
import React from "react";

export function DropdownComponent({
  titulo,
  dados,
  onChange,
  value,
  disabled,
}) {
  return (
    <>
      <div className="">
        <Select
          disabled={disabled}
          label={titulo}
          value={value}
          onChange={(v) => {
            onChange(v);
          }}
        >
          {dados.map((e) => (
            <Option key={e} value={e}>
              {e}
            </Option>
          ))}
        </Select>
      </div>
      <div className="h-2"></div>
    </>
  );
}

DropdownComponent.propTypes = {
  titulo: PropTypes.string.isRequired,
  dados: PropTypes.array.isRequired,
};

export default DropdownComponent;
