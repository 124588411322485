import { useState } from "react";

export default function useKM() {
  function getKM() {
    const kmValue = sessionStorage.getItem("km");
    return parseInt(kmValue);
  }

  const [km, setKM] = useState(getKM());

  const saveKM = (kmTotal) => {
    sessionStorage.setItem("km", kmTotal);
    setKM(kmTotal);
  };

  return {
    setKM: saveKM,
    km,
  };
}
