import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import {
  jsonDefaultRelatorioModel,
  RelatorioModel,
} from "../../models/relatorioModel";
import { DropdownComponent, TextField } from "../../widgets/inputs";
import DatePickerCustom from "../../widgets/inputs/datepicker";
import Row from "../../widgets/layout/row";
import { createExcel } from "../../widgets/table/createExcel";
import { TableCustom } from "../../widgets/table/table";

import useToken from "../../hooks/useToken";

import ApiRelatorio from "../../services/relatorio/relatorio";

import {
  jsonDefaultStatusAtividadeModel,
  StatusAtividadeModel,
} from "../../models/statusAtividadeModel";

import { ApiStatusAtividade } from "../../services/statusAtividade/statusAtividade";

export function Relatorios() {
  const { token, setToken } = useToken();

  const [cliente, setCliente] = useState("");
  const [numeroPedido, setNumeroPedido] = useState("");
  // const [entregador, setEntregador] = useState("")
  const [statusAtividade, setstatusAtividade] = useState("");
  var hoje = new Date();
  var diasAntes = new Date();
  diasAntes.setDate(diasAntes.getDate() - 3);
  const [startDate, setStartDate] = useState(diasAntes);
  const [endDate, setEndDate] = useState(hoje);
  const [relatorio, setRelatoriosModel] = useState(
    new RelatorioModel(jsonDefaultRelatorioModel)
  );
  const [isloading, setIsLoading] = useState(false);
  const [isloadingExcel, setIsLoadingExcel] = useState(false);
  const [statusAtividadeModel, setStatusAtividadeModel] = useState(
    new StatusAtividadeModel(jsonDefaultStatusAtividadeModel)
  );

  // var [entregadores, setEntregadores] = useState(
  //   new EntregadoresModel(jsonDefaultEntregadores)
  // )

  useEffect(() => {
    async function fetchData() {
      var response = await ApiStatusAtividade.statusAtividade({ token });
      if (response != null) {
        setStatusAtividadeModel(new StatusAtividadeModel(response));
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <br />

      <div>
        <Row>
          <TextField
            titulo="Nome do Cliente"
            onChange={(a) => {
              setCliente(a.target.value);
            }}
          />
          <TextField
            titulo="Numero do Pedido"
            onChange={(a) => {
              setNumeroPedido(a.target.value);
            }}
          />

          {/* <div style={{ width: "110%" }}>
            <DropdownComponent
              titulo="Entregador da LS"
              value={entregador}
              dados={entregadores.Entregadores.map((a) => {
                var dado = a.ID + " - " + a.Nome
                return String(dado)
              })}
              onChange={(a) => {
                setEntregador(a)
              }}
            />
          </div> */}
          <div
            style={{
              width: "110%",
            }}
          >
            <DropdownComponent
              titulo="Status da Atividade"
              selected={statusAtividade}
              dados={
                statusAtividadeModel.statusAtividades.map((a) => {
                  return String(a.Nome);
                }) || []
              }
              onChange={(a) => {
                setstatusAtividade(a);
              }}
            />
          </div>
        </Row>
        <Row>
          <div className="flex-grow text-gray-600 text-sm font-bold w-full">
            <div
              style={{
                marginLeft: "2px",
              }}
            >
              Data inicial
            </div>
            <DatePickerCustom
              defaultDate={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>

          <div className="flex-grow text-gray-600 text-sm font-bold mb-2 w-full">
            <div
              style={{
                marginLeft: "2px",
              }}
            >
              Data final
            </div>
            <DatePickerCustom onChange={(date) => setEndDate(date)} />
          </div>
          <div
            style={{
              width: "110%",
              alignItems: "center",
              justifyContent: "end",
              display: "flex",
            }}
          >
            <Button
              style={{
                width: "100%",
              }}
              onClick={async () => {
                setIsLoading(true);
                var idStatusAtividade = "";
                if (compareDate(startDate, endDate)) {
                  if (statusAtividade != "") {
                    statusAtividadeModel.statusAtividades.map((a) => {
                      if (String(a.Nome) == statusAtividade) {
                        idStatusAtividade = a.ID;
                      }
                    });
                  }

                  try {
                    var response = await ApiRelatorio.relatorioConsut(
                      token,
                      cliente,
                      numeroPedido,
                      idStatusAtividade,
                      startDate,
                      endDate
                    );
                  } catch (e) {
                    setIsLoading(false);
                    Swal.fire("Erro", "Erro ao buscar dados!", "error");
                  }

                  if (response != []) {
                    setRelatoriosModel(new RelatorioModel(response));
                  }

                  if (response.length == 0) {
                    Swal.fire(
                      "Dados não encontrados",
                      "Não foram encontrados dados com os filtros selecionados!",
                      "info"
                    );
                  }
                } else {
                  Swal.fire(
                    "Verificar a Data",
                    "A data inicial não pode ser maior que a data final!",
                    "error"
                  );
                }
                setIsLoading(false);
              }}
            >
              {isloading ? (
                <div
                  class="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                  style={{}}
                ></div>
              ) : (
                <div
                  style={{
                    paddingBottom: "5px",
                    paddingTop: "5px",
                  }}
                >
                  Buscar
                </div>
              )}
            </Button>
            <div
              style={{
                width: "5%",
              }}
            ></div>
            <Button
              style={{
                width: "100%",
              }}
              color="green"
              onClick={async () => {
                try {
                  setIsLoadingExcel(true);
                  await createExcel(relatorio.Dados);
                } catch (e) {
                  Swal.fire("Erro", "Erro ao exportar dados!", "error");
                }
                setTimeout(() => {
                  setIsLoadingExcel(false);
                }, 1000);
              }}
            >
              {isloadingExcel ? (
                <div
                  class="flex items-center justify-center "
                  style={{
                    color: "white",
                    marginRight: "21px",
                    marginLeft: "21px",
                    height: 23.2,
                  }}
                >
                  <div
                    class="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  >
                    <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                      LoadingExcel...
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "3.6px",
                    marginBottom: "3.6px",
                  }}
                >
                  exportar
                </div>
              )}
            </Button>
          </div>
        </Row>
        <Row>
          <div className="w-full"></div>
        </Row>
        <div className="overflow-x-auto">
          <Row>
            <TableCustom
              categoria={[
                "Pedido",
                "Status",
                "Modalidade",
                "Cliente",
                "Recebedor",
                "Data Pedido",
                "Data Entrega",
                "Endereço",
              ]}
              dados={relatorio.Dados}
            ></TableCustom>
          </Row>
        </div>
        <br />
      </div>
    </>
  );
}

function compareDate(date1, date2) {
  if (date1 > date2) {
    return false;
  } else {
    return true;
  }
}

export default Relatorios;
