import Swal from "sweetalert2";
import urlLsFast from "../../utils/globalVars";
export class ApiStatusAtividade {
  static async statusAtividade({ token }) {
    try {
      const url = urlLsFast + "/relatorio/statusatividades";
      var params = {};
      const response = await fetch(
        url,
        // + "?" + new URLSearchParams(params),
        {
          method: "GET",
          headers: {
            accept: "*/*",
            Token: token,
          },
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      Swal.fire("Erro", "Erro ao exportar dados!", "error");

      return null;
    }
  }
}
export default ApiStatusAtividade;
