import React from 'react';
import Row from '../layout/row';

const Modal = ({ isOpen, onClose, children}) => {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <div className='padding-2'></div>
        <Row>
            <div style={{ flexGrow: 1 }} />
            <span
                className="close text-2xl padding-2 cursor-pointer" 
                onClick={onClose}
            >&times;</span>
        </Row>
        {children}
      </div>
    </div>
  );
};

export default Modal;
