import formatDate from "../../src/utils/dataFormat";

export const jsonDefaultRelatorioModel = [];

export class RelatorioModel {
  constructor(json) {
    this.Dados = json.map((a) => {
      return new Relatorio(a);
    });
  }
}

class Relatorio {
  constructor(json) {
    this.IDOS = json["IDOS"];
    this.OS = json["OS"];
    this.Identificador = json["Identificador"];
    this.Rastreio = json["Rastreio"] || "Sem rastreio";
    this.Cliente = json["Cliente"];
    this.Reentrega = json["Reentrega"] == true ? "SIM" : "NÃO";
    this.Agente = json["Agente"];
    this.Local = json["Local"];
    this.Status = json["Status"];
    this.Valor = json["Valor"];
    this.CEP = json["CEP"];
    this.NomeRecebedor = json["NomeRecebedor"];
    this.Modalidade = json["Modalidade"];
    this.DataEntrada = formatDate(json["DataEntrada"]);
    this.DataExpedicao = formatDate(json["DataExpedicao"]);
    this.DataTermino = formatDate(json["DataTermino"]);
    this.DataSLA = formatDate(json["DataSLA"]);
    this.Latitude = json["Latitude"];
    this.Longitude = json["Longitude"];

    this.Cidade = json["Cidade"];
    this.Endereco = json["Endereco"];
    this.UF = json["UF"];
    this.DataFisico = formatDate(json["DataFisico"]);
    this.SLAnoPrazo = json["SLAnoPrazo"];
  }
}

export default RelatorioModel;
