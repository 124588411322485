import React, { useState } from "react";
import { Button, Input } from "@material-tailwind/react";
import Swal from "sweetalert2";
import urlLsFast from "../../utils/globalVars";
import useToken from "../../hooks/useToken";

export function AlterarSenha() {
  const { token, setToken } = useToken();
  const [senhaAtual, setSenhaAtual] = useState("");
  const [novaSenha, setNovaSenha] = useState("");
  const [confirmarNovaSenha, setConfirmarNovaSenha] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (novaSenha !== confirmarNovaSenha) {
      Swal.fire("Erro", "As senhas não coincidem!", "error");
      setIsLoading(false);
      return;
    }

    try {
      await alterarSenha(senhaAtual, novaSenha);
    } catch (error) {
      Swal.fire("Erro", "Erro ao alterar a senha!", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const limparCampos = () => {
    setSenhaAtual("");
    setNovaSenha("");
    setConfirmarNovaSenha("");
  };

  const alterarSenha = async (senhaAtual, novaSenha) => {
    var dados = {
      token: token,
      senhaAtual: senhaAtual,
      novaSenha: novaSenha,
    };
    const url = urlLsFast + "/autenticacao/alterar-senha";
    const method = "POST";
    const headers = {
      "Content-Type": "application/json",
    };
    const body = JSON.stringify(dados);

    const response = await fetch(url, { method, headers, body });

    if (response.ok) {
      limparCampos();
      return Swal.fire("Sucesso", "Senha alterada com sucesso!", "success");
    } else {
      throw new Error("Erro ao alterar a senha!");
    }
  };

  return (
    <div className="max-w-md mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Alterar Senha</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="senhaAtual"
            className="block text-sm font-medium text-gray-700"
          >
            Senha Atual
          </label>
          <Input
            id="senhaAtual"
            type="password"
            value={senhaAtual}
            onChange={(e) => setSenhaAtual(e.target.value)}
            required
          />
        </div>
        <div>
          <label
            htmlFor="novaSenha"
            className="block text-sm font-medium text-gray-700"
          >
            Nova Senha
          </label>
          <Input
            id="novaSenha"
            type="password"
            value={novaSenha}
            onChange={(e) => setNovaSenha(e.target.value)}
            required
          />
        </div>
        <div>
          <label
            htmlFor="confirmarNovaSenha"
            className="block text-sm font-medium text-gray-700"
          >
            Confirmar Nova Senha
          </label>
          <Input
            id="confirmarNovaSenha"
            type="password"
            value={confirmarNovaSenha}
            onChange={(e) => setConfirmarNovaSenha(e.target.value)}
            required
          />
        </div>
        <Button
          type="submit"
          color="blue"
          className="w-full"
          disabled={isLoading}
        >
          {isLoading ? "Alterando..." : "Alterar Senha"}
        </Button>
      </form>
    </div>
  );
}

export default AlterarSenha;
