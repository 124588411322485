import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import PropTypes from "prop-types";

export function NovoPedidoCards({ color, icon, title, values }) {
  return (
    <Card>
      <CardHeader
        variant="gradient"
        color={color}
        className="absolute -mt-4 grid h-16 w-16 place-items-center"
      >
        {icon}
      </CardHeader>
      <CardBody className="p-4 text-right">
        <Typography variant="h6" className="font-bold text-blue-gray-600">
          {title}
        </Typography>
        <div className="h-4"></div>
        {values.map((e) => {
          return (
            <li className="flex items-center gap-4 w-full">
              <Typography
                variant="small"
                color={e.color}
                className="font-semibold capitalize"
              >
                {e.title}
              </Typography>
              <div className="flex-grow"></div>
              <Typography
                variant="small"
                color={e.color || "text-blue-gray-500"}
                className="font-normal "
              >
                {e.text}
              </Typography>
            </li>
          );
        })}
      </CardBody>
    </Card>
  );
}

NovoPedidoCards.defaultProps = {
  color: "blue",
  footer: null,
};

NovoPedidoCards.propTypes = {
  color: PropTypes.oneOf([
    "white",
    "blue-gray",
    "gray",
    "brown",
    "deep-orange",
    "orange",
    "amber",
    "yellow",
    "lime",
    "light-green",
    "green",
    "teal",
    "cyan",
    "light-blue",
    "blue",
    "indigo",
    "deep-purple",
    "purple",
    "pink",
    "red",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
  footer: PropTypes.node,
};

export default NovoPedidoCards;
