import PropTypes from "prop-types";
import { Typography } from "@material-tailwind/react";

export function Footer({ brandName, brandLink, routes }) {
    const year = new Date().getFullYear();

    return (
        <footer className="py-2">
            <div className="flex w-full flex-wrap items-center justify-center gap-6 px-2 md:justify-between">
                <Typography variant="small" className="font-normal text-inherit">
                    &copy; {year}, {" "}
                    <a
                        href={brandLink}
                        target="_blank"
                        className="transition-colors hover:text-blue-500"
                    >
                        {brandName}
                    </a>.
                </Typography>
                <ul className="flex items-center gap-4">
                    {routes.map(({ name, path }) => (
                        <li key={name}>
                            <Typography
                                as="a"
                                href={path}
                                target="_blank"
                                variant="small"
                                className="py-0.5 px-1 font-normal text-inherit transition-colors hover:text-blue-500"
                            >
                                {name}
                            </Typography>
                        </li>
                    ))}
                </ul>
            </div>
        </footer>
    );
}

Footer.defaultProps = {
    brandName: "LS Translog",
    brandLink: "https://lstranslog.com.br",
    routes: [
        { name: "Quem somos", path: "https://lstranslog.com.br/quem-somos" },
        { name: "Políticas de Privacidade", path: "https://lstranslog.com.br/politicas-de-privacidade" },
    ],
};

Footer.propTypes = {
    brandName: PropTypes.string,
    brandLink: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
