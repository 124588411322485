import {
  ArchiveBoxIcon,
  HomeIcon,
  PrinterIcon,
  TruckIcon,
} from "@heroicons/react/24/solid";
import { Home } from "./pages/dashboard";
import AgendamentoEntrega from "./pages/dashboard/agendamentoEntrega";
import CadastroPedido from "./pages/dashboard/cadastroPedido";

import CadastroPedidoReceita from "./pages/dashboard/cadastroPedidoReceita";

import Relatorio from "./pages/dashboard/relatorios";
import AlterarSenha from "./pages/dashboard/alterar-senha";
import { LockClosedIcon } from "@heroicons/react/24/outline";

const icon = {
  className: "w-5 h-5 text-inherit",
};

export const routes = [
  {
    layout: "dashboard",
    pages: [
      {
        icon: <HomeIcon {...icon} />,
        name: "dashboard",
        path: "/home",
        element: <Home />,
        view: true,
      },
      {
        icon: <ArchiveBoxIcon {...icon} />,
        name: "cadastro de pedido",
        path: "/cadastro/pedido",
        element: <CadastroPedido />,
        view: false,
      },
      {
        icon: <ArchiveBoxIcon {...icon} />,
        name: "Coleta de Receita",
        path: "/cadastro/pedido/receita",
        element: <CadastroPedidoReceita />,
        view: false,
      },
      {
        icon: <TruckIcon {...icon} />,
        name: "pedidos",
        path: "/pedidos",
        element: <AgendamentoEntrega />,
        view: true,
      },
      {
        icon: <PrinterIcon {...icon} />,
        name: "relatorio",
        path: "/relatorio",
        element: <Relatorio />,
        view: true,
      },
      {
        icon: <LockClosedIcon {...icon} />,
        name: "Alterar senha",
        path: "/senha",
        element: <AlterarSenha />,
        view: true,
      },
    ],
  },
];

export default routes;
