const XLSX = require("xlsx")
const workbook = XLSX.utils.book_new()

export async function createExcel(dados) {
  //pegar so as 18 primeiras colunas
  var keys = await dados.map((a) => {
    return Object.keys(a).slice(0, 19)
  })
  //pegar so as 18 primeiras colunas
  var dados = await dados.map((a) => {
    return Object.values(a).slice(0, 19)
  })

  var data = [keys[0], ...dados]
  //tamanho das colunas
  var wscols = [
    { wch: 10 },
    { wch: 15 },
    { wch: 15 },
    { wch: 10 },
    { wch: 25 },
    { wch: 5 },
    { wch: 25 },
    { wch: 15 },
    { wch: 15 },
    { wch: 10 },
    { wch: 8 },
    { wch: 25 },
    { wch: 8 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 10 },
    { wch: 10 },
  ]

  var worksheet = XLSX.utils.aoa_to_sheet(data)
  worksheet["!cols"] = wscols
  XLSX.utils.book_append_sheet(workbook, worksheet, "")
  await XLSX.writeFile(workbook, "Planilha.xlsx")
}

export default createExcel
