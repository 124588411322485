import { chartsConfig } from "../configs";

var websiteViewsChart = {
  type: "bar",
  height: 220,
  series: [
    {
      name: "Pedidos",
      data: [50, 20, 10, 22, 50, 10, 40],
    },
  ],
  options: {
    ...chartsConfig,
    colors: "#fff",
    plotOptions: {
      bar: {
        columnWidth: "16%",
        borderRadius: 5,
      },
    },
    xaxis: {
      ...chartsConfig.xaxis,
      categories: ["M", "T", "W", "T", "F", "S", "S"],
    },
  },
};

var dailySalesChart = {
  type: "line",
  height: 220,
  series: [
    {
      name: "pedidos",
      data: [0, 0, 0, 0, 0],
    },
  ],
  options: {
    ...chartsConfig,
    colors: ["#fff"],
    stroke: {
      lineCap: "round",
    },
    markers: {
      size: 5,
    },
    xaxis: {
      ...chartsConfig.xaxis,
      categories: ["0", "1", "2", "3", "4"],
    },
  },
};

var dailySalesChart2 = {
  type: "bar",
  height: 220,
  options: {
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      lineCap: "round",
    },
    markers: {
      size: 5,
    },
    xaxis: {
      categories: [
        "Endereço não localizado",
        "Cliente ausente",
        "Cancelado pelo cliente",
        "Numero não localizado",
        "Pedido Extraviado",
        "Pedido Recusado",
        "Cliente Inexistente",
      ],
    },
  },
  series: [
    {
      name: "Ocorrências",
      data: [
        {
          x: "Endereço não localizado",
          y: 20,
          fillColor: "#FF6384",
        },
        {
          x: "Cliente ausente",
          y: 18,
          fillColor: "#36A2EB",
        },
        {
          x: "Cancelado pelo cliente",
          y: 15,
          fillColor: "#FFCD56",
        },
        {
          x: "Numero não localizado",
          y: 13,
          fillColor: "#1B713B",
        },
        {
          x: "Pedido Extraviado",
          y: 4,
          fillColor: "#F96989",
        },
        {
          x: "Pedido Recusado",
          y: 2,
          fillColor: "#777575",
        },
        {
          x: "Cliente Inexistente",
          y: 1,
          fillColor: "#FF6082",
        },
      ],
    },
  ],
};

var completedTasksChart = {
  ...dailySalesChart2,
};

export var statisticsChartsData = [
  {
    index: 0,
    color: "blue",
    background: null,
    title: "Pedidos concluídos",
    description: "Total de entregas concluídos por dia da semana",
    footer: "Últimos 7 dias",
    chart: websiteViewsChart,
  },
  {
    index: 1,
    color: "green",
    background: null,
    title: "SLA das entregas",
    description: "Calculado entre cadastro e entrega",
    footer: "Sobre os pedidos entregues",
    chart: dailySalesChart,
  },
  {
    index: 2,
    color: "pink",
    background: true,
    title: "Ocorrências das entregas",
    description: "Os motivos do pedido não ser entregue.",
    footer: "No momento da tentativa da entrega",
    chart: completedTasksChart,
  },
];

export default statisticsChartsData;
