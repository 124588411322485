class PedidoModel {
  constructor(
    id,
    os,
    dataEntrada,
    loja,
    clienteNome,
    enderecoCliente,
    valorReceber,
    entregadorNome,
    fotoEntregador,
    chegadaLoja,
    entregaPedido,
    frete,
    statusPedido,
    modalidadeEntrega,
    horario,
    deletar,
    reentrega,
    cep,
    endereco,
    numero,
    complemento,
    pontoReferencia,
    bairro,
    uf,
    cidade,
    telefone,
    receberValor,
    alterarHorario,
    receita,
    dataSLA,
    dataTermino
  ) {
    this.endereco = endereco;
    this.numero = numero;
    this.complemento = complemento;
    this.pontoReferencia = pontoReferencia;
    this.bairro = bairro;
    this.cidade = cidade;
    this.uf = uf;
    this.telefone = telefone;
    this.cep = cep;
    this.id = id;
    this.os = os;
    this.dataEntrada = dataEntrada;
    this.loja = loja;
    this.clienteNome = clienteNome;
    this.enderecoCliente = enderecoCliente;
    this.valorReceber = valorReceber;
    this.entregadorNome = entregadorNome;
    this.fotoEntregador = fotoEntregador;
    this.chegadaLoja = chegadaLoja;
    this.entregaPedido = entregaPedido;
    this.frete = frete;
    this.statusPedido = statusPedido;
    this.modalidadeEntrega = modalidadeEntrega;
    this.horario = horario;
    this.deletar = deletar;
    this.reentrega = reentrega;
    this.receberValor = receberValor;
    this.alterarHorario = alterarHorario;
    this.receita = receita;
    this.dataSLA = dataSLA;
    this.dataTermino = dataTermino;
  }

  copyWith({
    endereco = this.endereco,
    numero = this.numero,
    complemento = this.complemento,
    pontoReferencia = this.pontoReferencia,
    bairro = this.bairro,
    cidade = this.cidade,
    uf = this.uf,
    telefone = this.telefone,
    cep = this.cep,
    id = this.id,
    os = this.os,
    dataEntrada = this.dataEntrada,
    loja = this.loja,
    clienteNome = this.clienteNome,
    enderecoCliente = this.enderecoCliente,
    valorReceber = this.valorReceber,
    entregadorNome = this.entregadorNome,
    fotoEntregador = this.fotoEntregador,
    chegadaLoja = this.chegadaLoja,
    entregaPedido = this.entregaPedido,
    frete = this.frete,
    statusPedido = this.statusPedido,
    modalidadeEntrega = this.modalidadeEntrega,
    horario = this.horario,
    deletar = this.deletar,
    reentrega = this.reentrega,
    receberValor = this.receberValor,
    alterarHorario = this.alterarHorario,
    receita = this.receita,
    dataSLA = this.dataSLA,
    dataTermino = this.dataTermino,
  }) {
    return new PedidoModel(
      endereco,
      numero,
      complemento,
      pontoReferencia,
      bairro,
      cidade,
      uf,
      telefone,
      cep,
      id,
      os,
      dataEntrada,
      loja,
      clienteNome,
      enderecoCliente,
      valorReceber,
      entregadorNome,
      fotoEntregador,
      chegadaLoja,
      entregaPedido,
      frete,
      statusPedido,
      modalidadeEntrega,
      horario,
      deletar,
      reentrega,
      receberValor,
      alterarHorario,
      receita,
      dataSLA,
      dataTermino
    );
  }
}

export default PedidoModel;
