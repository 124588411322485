import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import useToken from "./hooks/useToken";
import { Dashboard } from "./layouts";
import { SignIn } from "./pages/auth/sign-in";

export default function App() {
  const { token, setToken } = useToken();
  const location = useLocation();

  const path = location.pathname;
  console.log(path);
  if (!token) {
    if (path !== "/auth/signin") {
      return <Navigate to="/auth/signin" replace />;
    }
    return <SignIn setToken={setToken} />;
  } else {
    if (path === "/auth/signin") {
      return <Navigate to="/dashboard/home" replace />;
    }
  }

  return (
    <Routes>
      <Route path="/auth/*" element={<SignIn setToken={setToken} />} />
      <Route path="/dashboard/*" element={<Dashboard />} />
      <Route path="/*" element={<Navigate to="/dashboard/home" replace />} />
    </Routes>
  );
}
