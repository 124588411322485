import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import useCodigoLocal from "../../hooks/useCodigoLocal";
import useIdLocal from "../../hooks/useIdLocal";
import useInfoLoja from "../../hooks/useInfoLoja";
import useKM from "../../hooks/useKM";
import useNome from "../../hooks/useName";
import userReceita from "../../hooks/useReceita";
import { LoginAutoExternal, LoginExternal } from "../../services/auth";
import AlertaErro from "../../widgets/alertas/erro";
import AlertaSucesso from "../../widgets/alertas/sucesso";
import { HomologacaoCard } from "../../widgets/charts";
import buscarInformacoesDaLoja from "./buscar_loja";

export function SignIn({ setToken, setInfoLoja }) {
  var { nome, setNome } = useNome();
  var { idLocal, setIdLocal } = useIdLocal();
  var { codigoLocal, setCodigoLocal } = useCodigoLocal();
  var { km, setKM } = useKM();
  var { receita, setReceita } = userReceita(false);

  var { infoLoja, setInfoLoja } = useInfoLoja();

  const notify = (e) => toast(e);
  var navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSignIn = async () => {
    setLoading(true);
    if (email == "" || password == "") {
      notify(<AlertaErro text={"Preencha todos os campos!"} />);
      setLoading(false);
      return;
    }
    var dados = await LoginExternal(email, password);
    await buscarInformacoesDaLoja(
      dados["Token"],
      dados["loja"]["IDLocal"],
      setInfoLoja
    );
    notify(<AlertaErro text={"Usuário ou senha incorretos!"} />);
    if (dados == null) {
    } else {
      var token = dados["Token"];
      setNome(dados["Nome"]);
      setIdLocal(dados["loja"]["IDLocal"]);
      setCodigoLocal(dados["loja"]["Codigo"]);

      setReceita(dados["loja"]["ColetarReceita"]);
      setKM(dados["loja"]["KM"]);

      if (token == null) {
        notify(<AlertaErro text={"Usuário ou senha incorretos!"} />);
      } else {
        notify(<AlertaSucesso text={"Login realizado com sucesso!"} />);
        setToken(token);
        navigate("/home");
      }
    }

    setLoading(false);
  };

  const autoLogin = async (tokenParam) => {
    setLoading(true);
    var dados = await LoginAutoExternal(tokenParam);
    if (dados == null) {
      navigate("/auth/signin");
    } else {
      await buscarInformacoesDaLoja(
        dados["Token"],
        dados["loja"]["IDLocal"],
        setInfoLoja
      );
      var token = dados["Token"];
      setNome(dados["Nome"]);
      setIdLocal(dados["loja"]["IDLocal"]);
      setCodigoLocal(dados["loja"]["Codigo"]);

      setReceita(dados["loja"]["ColetarReceita"]);
      setKM(dados["loja"]["KM"]);

      if (token == null) {
        navigate("/auth/signin");
      } else {
        notify(<AlertaSucesso text={"Login realizado com sucesso!"} />);
        setToken(token);
        navigate("/home");
      }
    }
    setLoading(false);
  };

  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const token = urlParams.get("token");
    if (token) {
      autoLogin(token);
    }
  }, []);

  if (urlParams.get("logout")) {
    const image = (
      <img
        src="/assets/img/login_background.png"
        className="absolute inset-0 z-0 h-full w-full object-cover"
        alt=""
        style={{
          filter: "brightness(0.9)",
        }}
      />
    );
    const toastItem = (
      <div className="absolute bottom-0 right-10">
        <ToastContainer />
      </div>
    );
    return (
      <>
        {image}
        <div className="absolute inset-0 z-0 h-full w-full bg-black/50" />
        <HomologacaoCard />
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "5rem",
              width: "5rem",
            }}
          >
            <CardHeader className="w-full h-full grid place-items-center">
              <div
                class="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
                style={{
                  height: "2.5rem",
                  width: "2.5rem",
                  border: "4px solid #f47321",
                  borderRightColor: "transparent",
                }}
              >
                {" "}
              </div>
            </CardHeader>
          </div>
        </div>
        {toastItem}
      </>
    );
  }

  return (
    <>
      <img
        src="/assets/img/login_background.png"
        className="absolute inset-0 z-0 h-full w-full object-cover"
        alt=""
        style={{
          filter: "brightness(0.9)",
        }}
      />
      <div className="absolute inset-0 z-0 h-full w-full bg-black/50" />
      <HomologacaoCard />
      <div className="container mx-auto p-4">
        <Card className="absolute top-2/4 left-2/4 w-full max-w-[24rem] -translate-y-2/4 -translate-x-2/4">
          <CardHeader
            className="mb-4 grid h-28 place-items-center "
            style={{
              background: "rgb(244, 115, 33)",
            }}
          >
            <img
              src="/icon.png"
              className="h-20"
              alt=""
              style={{
                boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.25)",
                borderRadius: "50%",
                scale: "1.2",
              }}
            />
          </CardHeader>
          <CardBody className="flex flex-col gap-4">
            <Input
              onChange={(e) => setEmail(e.target.value)}
              color="orange"
              type="email"
              label="Email"
              size="lg"
            />
            <Input
              onChange={(e) => setPassword(e.target.value)}
              color="orange"
              type="password"
              label="Senha"
              size="lg"
            />
          </CardBody>
          <CardFooter className="pt-0">
            <Button
              onClick={handleSignIn}
              variant="gradient"
              style={{
                background: "rgb(244, 115, 33)",
                width: "100%",
                height: "3rem",
              }}
              fullWidth
            >
              {loading ? (
                <div
                  style={{ height: "2.5rem" }}
                  class="flex items-center justify-center"
                  className="w-full"
                >
                  <div
                    class="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  >
                    <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                      LoadingExcel...
                    </span>
                  </div>
                </div>
              ) : (
                "Entrar"
              )}
            </Button>
          </CardFooter>
        </Card>
      </div>
      <div className="absolute bottom-0 right-10">
        <ToastContainer />
      </div>
    </>
  );
}

export default SignIn;
