export async function verificarHoraFuncionamento(token, horario) {
  return true;
  //converter 18:00 para o iso8601
  // var horarioIso86 = horario + ":00";
  // let response;
  // let params = {
  //   horario: horarioIso86,
  // };
  // const urlLsFastLocal = `${urlLsFast}/pedido/validarhora`;
  // try {
  //   response = await fetch(urlLsFastLocal + "?" + new URLSearchParams(params), {
  //     method: "GET",
  //     headers: {
  //       accept: "*/*",
  //       Token: token,
  //     },
  //   });
  //   if (response.status === 200) {
  //     const json = await response.json();

  //     if (json["Valido"] == true) {
  //       console.log("horario valido");
  //       return true;
  //     } else {
  //       //formatar hora bt 00:00
  //       var horaInicio = horaFormat(json["InicioDia"]);
  //       var horaFim = horaFormat(json["FimDia"]);
  //       Swal.fire({
  //         title: "Aviso",
  //         text:
  //           "Nosso horário de coleta é de " + horaInicio + " até " + horaFim,
  //         icon: "warning",
  //         confirmButtonText: "Ok",
  //       });
  //       return false;
  //     }
  //   } else {
  //     return null;
  //   }
  // } catch (error) {
  //   console.error(error);
  //   return null;
  // }
}

export default verificarHoraFuncionamento;
