import { useState } from "react";

export default function useIdLocal() {
  function getIdLocal() {
    const valueString = sessionStorage.getItem("idLocal");
    return valueString;
  }

  const [idLocal, setIdLocal] = useState(getIdLocal());

  const saveIdLocal = (userToken) => {
    sessionStorage.setItem("idLocal", userToken);
    setIdLocal(userToken);
  };

  return {
    setIdLocal: saveIdLocal,
    idLocal,
  };
}
