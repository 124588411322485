import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import Swal from "sweetalert2";
import { ApiPedidos } from "../../services/pedidos";
import Row from "../../widgets/layout/row";

export function InfoPedidoCard({ pedido, editar, excluir }) {
  var dadosComplemento = "";
  if (pedido.complemento) {
    dadosComplemento += `Complemento: ${pedido.complemento}`;
  }
  if (pedido.pontoReferencia) {
    if (dadosComplemento != "") {
      dadosComplemento += " - ";
    }
    dadosComplemento += `Ponto de Referência: ${pedido.pontoReferencia}`;
  }
  return (
    <div className="mt-8">
      <Card>
        <CardBody className="p-4 text-left">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "70%" }}>
              <Typography variant="h5" color="blue-gray">
                {pedido.os} - {pedido.clienteNome}
              </Typography>
              {/* {pedido.reentrega == true ? (
                <Typography variant="h8" color="blue-gray">
                  Reentrega
                </Typography>
              ) : null} */}
              {/* <Typography
                variant="small"
                className="font-normal text-blue-gray-600"
              >
                {pedido.loja}
              </Typography> */}
              <Typography
                variant="small"
                className="font-normal text-blue-gray-600"
              >
                {pedido.enderecoCliente}
              </Typography>
              {dadosComplemento == "" ? null : (
                <Typography
                  variant="small"
                  className="font-normal text-blue-gray-600"
                >
                  {dadosComplemento}
                </Typography>
              )}

              {/* data inclusao  */}
              <Typography
                variant="small"
                className="font-normal text-blue-gray-600"
              >
                Data inclusão: {pedido.dataEntrada}
              </Typography>

              {pedido.valorReceber == 0 ? null : (
                <Typography
                  variant="small"
                  className="font-normal text-blue-gray-900"
                >
                  Valor a receber: R$
                  {pedido.valorReceber.toFixed(2).replace(".", ",")}
                </Typography>
              )}

              {/* {pedido.receita ? (
                <div>
                  <div style={{ height: 8 }} />
                  <Typography
                    variant="h8"
                    color="blue-gray"
                    style={{
                      color: "#4299E1",
                      fontWeight: "bold",
                    }}
                  >
                    Receita
                  </Typography>
                </div>
              ) : null} */}

              <div style={{ height: 8 }} />
              <Row>
                {pedido.deletar ? (
                  <div
                    style={{ display: "flex", flexDirection: "row" }}
                    className="gap-3"
                  >
                    {/* <Button onClick={() => editar(pedido)}>
                    {" "}
                    Editar pedido{" "}
                  </Button> */}
                    <Button
                      onClick={async () => {
                        Swal.fire({
                          title: "Atenção!",
                          text: "Você tem certeza que deseja excluir esse pedido?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Sim, excluir",
                          cancelButtonText: "Cancelar",
                        }).then(async (result) => {
                          if (result.isConfirmed) {
                            var response = await excluir(pedido);
                            if (response["erro"]) {
                              Swal.fire("Erro!", response["mensagem"], "error");
                            } else {
                              Swal.fire(
                                "Excluido!",
                                response["mensagem"],
                                "success"
                              );
                            }
                          }
                        });
                      }}
                      className="bg-red-400"
                    >
                      {" "}
                      Excluir pedido{" "}
                    </Button>
                  </div>
                ) : null}
                {pedido.statusPedido == "Coleta Pedidos" ||
                pedido.statusPedido == "Integrado" ? (
                  <div style={{ width: "30%" }}>
                    <Button
                      onClick={async () => {
                        await ApiPedidos.gerarEtiquetas([pedido.id]);
                      }}
                      style={{
                        backgroundColor: "#dd6b20",
                      }}
                    >
                      {" "}
                      IMPRIMIR ETIQUETA{" "}
                    </Button>
                  </div>
                ) : null}

                {pedido.statusPedido == "Devolvido" ? (
                  <div
                    style={{ display: "flex", flexDirection: "row" }}
                    className="gap-3"
                  >
                    <Button
                      style={{
                        paddingLeft: 40,
                        paddingRight: 40,
                      }}
                      onClick={async () => {
                        editar();
                      }}
                      className="bg-blue-400"
                    >
                      TENTAR UMA NOVA ENTREGA
                    </Button>
                  </div>
                ) : null}
              </Row>
            </div>
            <div>
              {pedido.entregadorNome == "" ? null : (
                <div>
                  <Typography
                    variant="small"
                    className="font-normal text-blue-gray-600"
                  >
                    Entregador da LS:
                  </Typography>
                  <div style={{ height: 2 }} />
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    <div
                      style={{
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        paddingRight: 50,
                      }}
                    >
                      {pedido.entregadorNome}
                    </div>
                  </Typography>
                </div>
              )}
              {/* {pedido.chegadaLoja == "" && pedido.entregaPedido == "" ? null : (
                <Typography
                  variant="small"
                  className="font-normal text-blue-gray-600"
                >
                  Previsão:
                </Typography>
              )} */}
              {pedido.dataSLA == "" ? null : (
                <Typography
                  variant="small"
                  className="font-normal text-blue-gray-600"
                >
                  {" - Previsão de entrega: "}
                  {pedido.dataSLA} hrs
                </Typography>
              )}
              {pedido.dataTermino == "" ? null : (
                <Typography
                  variant="small"
                  className="font-normal text-blue-gray-600"
                >
                  {" - Horário de conclusão: "}
                  {pedido.dataTermino} hrs
                </Typography>
              )}
              <div style={{ height: 8 }} />
              {pedido.frete == 0 ? null : (
                <Typography
                  variant="small"
                  className="font-normal text-blue-gray-600"
                >
                  Frete: R${pedido.frete.toFixed(2).replace(".", ",")}
                </Typography>
              )}
            </div>
          </div>
        </CardBody>
        {pedido.entregadorNome == "" ? null : (
          <CardHeader
            variant="gradient"
            color="white"
            className="right-2 absolute -mt-6 grid h-17 w-16 place-items-center"
          >
            <img
              // esticar a imagem
              style={{ width: "100%", height: "100%" }}
              src={pedido.fotoEntregador}
              onClick={async () => {
                await Swal.fire({
                  title: pedido.entregadorNome,
                  imageUrl: pedido.fotoEntregador,
                  imageWidth: 300,
                  imageHeight: 500,
                  imageAlt: "Foto do entregador",

                  confirmButtonText: "Fechar",
                });
              }}
            ></img>
          </CardHeader>
        )}

        <CardFooter className="border-t border-blue-gray-50 p-4">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "70%" }}>
              <Row>
                {/* {pedido.receita ? (
                  <Typography
                    variant="small"
                    className="font-normal text-blue-gray-600"
                  >
                    Coleta de Receita -
                  </Typography>
                ) : null} */}
                <Typography
                  variant="small"
                  className="font-normal text-blue-gray-600"
                >
                  Status:
                </Typography>
                <Typography
                  variant="small"
                  className="font-normal text-blue-gray-600"
                  style={{
                    color: pedido.statusPedido == "Devolvido" ? "red" : "",
                    fontWeight:
                      pedido.statusPedido == "Devolvido" ? "bold" : "",
                    marginLeft: -5,
                  }}
                >
                  {pedido.statusPedido}
                </Typography>
              </Row>
            </div>

            <div style={{ width: "30%" }}>
              <Typography
                variant="small"
                className="font-normal text-blue-gray-600 text-right"
              >
                {pedido.modalidadeEntrega}
              </Typography>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}

export default InfoPedidoCard;
